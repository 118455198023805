import React, { useEffect, useState } from 'react'
import { Row, Card, ListGroup, Container } from 'react-bootstrap'
import NavBar from '../components/NavBar'
import { useDispatch, useSelector } from 'react-redux'
import { getMaster } from '../actions/homeActions'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import Charts from '../components/Charts'
function HomeScreen(props) {
    const [tests, setTests] = useState([])
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector(state => state.user)
    const home = useSelector(state => state.home)
    const data = home.data
    useEffect(() => {
        if (props.location.state) {
            toast.success(props.location.state.message, {
                autoClose: false,
                position: "top-right",
                progress: undefined,
                draggable: true,
                closeOnClick: true
            })
            history.replace('/home')
        }

    }, [props.location.state])
    useEffect(() => {
        if (user.token != "") {
            dispatch(getMaster())
        }
    }, [user.token]);
    useEffect(() => {
        let tests = [];
        data.forEach(item => {
            tests.push(
                <Card border="light" style={{ width: '18rem' }} className="m-2 shadow-sm p-1 mb-5 bg-white rounded" key={item.id}>
                    <Card.Body>
                        <Card.Title>{item.name}</Card.Title>
                        <ListGroup >
                            {item.active_tests.map(element => (
                                element.user_submission == null ?
                                    <ListGroup.Item
                                        action
                                        key={element.id}
                                        onClick={() => {
                                            history.push('/test', { fromHome: true, test_id: element.id })
                                        }}>{element.name}</ListGroup.Item> :
                                    <ListGroup.Item
                                        variant="success"
                                        action
                                        key={element.id}
                                        onClick={() => {
                                            history.push('/test', { fromHome: true, test_id: element.id, submission: element.user_submission })
                                        }}>{element.name}</ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Card.Body>
                </Card >)
        });
        setTests(tests)
    }, [data])
    return (
        <div>
            <NavBar />
            <Container className="p-5">
                <Charts/>
                <Card className="mt-5 m-auto shadow p-3 mb-5 bg-white rounded" >
                    <Card.Title className="mb-2">Tests</Card.Title>
                    <Card.Body>
                        <Row style={{ display: 'flex', justifyContent: "space-around" }}>
                            {tests}
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    )
}

export default HomeScreen
