import React, { useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import ShowToast from '../../../utils/ShowToast'
import store from '../../../utils/store'
import makeRequest from '../../../utils/makeRequest'
import { AdminContext } from '../../../utils/AdminProvider'
import BtnDark from '../../../components/BtnDark'
function AddStudent(props) {
    const [name, setName] = useState("")
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [email, setEmail] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [loading, setLoading] = useState(false)
    const { generateErrors, setTutors } = React.useContext(AdminContext)
    const validate = () => {
        if (!name || name.trim() === "") {
            ShowToast('error', "Please enter name")
            return
        }
        if (!username || username.trim() === "") {
            ShowToast('error', "Please enter username")
            return
        }
        if (!email || email.trim() === "") {
            ShowToast('error', "Please enter email")
            return
        }
        if (!password || password.trim() === "") {
            ShowToast('error', "Please select password")
            return
        }
        if (confirmPassword !== password) {
            ShowToast('error', "Passwords dont match")
            return
        }
        register()

    }
    const register = () => {
        let data = new FormData()
        data.append("name", name)
        data.append("username", username)
        data.append("password", password)
        data.append("email", email)
        setLoading(true)
        makeRequest(store.getState().user.token).post('/registerTutor', data).then(res => {
            setLoading(false)
            if (res.data.status_code === 200) {
                setTutors(res.data.data)
                ShowToast('success', res.data.message)
                props.onHide()
            } else {
                generateErrors(res.data.errors)
            }
        }).catch(error => {
            setLoading(false)
            ShowToast('error', "Something went wrong!")
        })
    }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add Tutor
          </Modal.Title>
            </Modal.Header><Modal.Body>
                <Form id="add-form">
                    <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="name" placeholder="Enter Name" onChange={(event) => { setName(event.target.value) }} />
                    </Form.Group>
                    <Form.Group controlId="username">
                        <Form.Label>Username</Form.Label>
                        <Form.Control type="username" placeholder="Enter username" onChange={(event) => { setUsername(event.target.value) }} />
                    </Form.Group>
                    <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Enter Email" onChange={(event) => { setEmail(event.target.value) }} />
                    </Form.Group>
                    <Form.Group controlId="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" onChange={(event) => { setPassword(event.target.value) }} />
                    </Form.Group>
                    <Form.Group controlId="confirm_password">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" onChange={(event) => { setConfirmPassword(event.target.value) }} />
                    </Form.Group>
                    <BtnDark
                        title="Submit"
                        loading={loading}
                        onClick={(event) => {
                            event.preventDefault()
                            validate()
                        }} />
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default React.memo(AddStudent)
