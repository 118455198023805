import React, { useContext, useState, useEffect } from 'react'
import { Container, Card, Button, ListGroup, Row } from 'react-bootstrap';
import { AppContext } from '../utils/AppProvider';
import { useHistory } from 'react-router-dom';
import WebStorageInstance from '../utils/storage';
import Confetti from 'react-confetti'
import ShowToast from '../utils/ShowToast';
import { toast } from 'react-toastify';


function SubjectHomework(props) {
    const [sets, setSets] = useState()
    const { homework } = useContext(AppContext)
    const [subjectName, setSubjectName] = useState("")
    const history = useHistory();
    const [celebrate, setCelebrate] = useState(false)
    useEffect(() => {
        if (props.location.state) {
            toast.success(props.location.state.message, {
                autoClose: false,
                position: "top-right",
                progress: undefined,
                draggable: true,
                closeOnClick: true
            })
            history.replace(`/home/subject/${props.match.params.subject}`)
            if (props.location.state.celebrate == true) {
                setCelebrate(true)
                setTimeout(() => {
                    setCelebrate(false)
                }, 10000);
            }
        }

    }, [props.location.state])
    useEffect(() => {
        generateSets()
    }, [homework, props.match.params.subject])
    const generateSets = () => {
        let subject = null
        if (props.match.params.subject) {
            subject = props.match.params.subject
        } else if (WebStorageInstance.getItem('@subject')) {
            subject = WebStorageInstance.getItem('@subject')
        } else {
            subject = null
        }
        if (subject !== null) {
            let s = []
            homework.forEach(item => {
                if (item.subject_id == subject) {
                    setSubjectName(item.subject_name)
                    item.homeworks.map((element, index) => {
                        if(element.week_homework.length == 0) return
                        return (
                            s.push(
                                <Card border="light" style={{ width: '18rem' }} className="m-2 shadow-sm p-1 mb-5 bg-white rounded" key={`week${element.week}`}>
                                    <Card.Body>
                                        <Card.Title>{`Week ${element.week}`}</Card.Title>
                                        <ListGroup >
                                            {element.week_homework.map(set => (
                                                set.user_submission == null ?
                                                    <ListGroup.Item
                                                        action
                                                        key={set.id}
                                                        onClick={() => {
                                                            history.push('/homework', {
                                                                fromHome: true,
                                                                homework_id: set.id,
                                                                subject_name: item.subject_name,
                                                                week_name: set.week,
                                                                subject_id: item.subject_id
                                                            })
                                                        }}>{set.name}</ListGroup.Item> :
                                                    <ListGroup.Item
                                                        variant="success"
                                                        action
                                                        key={set.id}
                                                        onClick={() => {
                                                            history.push('/homework', {
                                                                fromHome: true,
                                                                homework_id: set.id,
                                                                subject_name: item.subject_name,
                                                                week_name: set.week,
                                                                subject_id: item.subject_id
                                                            })
                                                        }}>{
                                                            set.user_submission.score == set.user_submission.total_questions ?
                                                                <div className="align-items-center d-flex justify-content-between">
                                                                    {set.name}
                                                                    <img src={require('../assets/full_marks.png')} style={{ height: 24, width: 24 }} />
                                                                </div>
                                                                : set.name
                                                        }
                                                    </ListGroup.Item>
                                            ))}
                                        </ListGroup>
                                    </Card.Body>
                                </Card >
                            )
                        )
                    })
                }

            });
            setSets(s)
        }
    }
    return (
        <div>
            {celebrate && <Confetti width={window.innerWidth} height={window.innerHeight} />}
            <Card className="mt-5 m-auto shadow p-3 mb-5 bg-white rounded align-self-right" >
                <Card.Title className="mb-2">{subjectName}</Card.Title>
                <Card.Body>
                    <Row>
                        {sets}
                    </Row>
                </Card.Body>
            </Card>
        </div>
    )
}
export default SubjectHomework