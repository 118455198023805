import * as t from './types'
import makeRequest from '../utils/makeRequest'
import store from '../utils/store'

export const getTestData = ({
    type: t.GET_TEST_DATA
})

export const getTestDataSuccess = data => ({
    type: t.GET_TEST_DATA_SUCCESS,
    payload: data
})

export const getTestDataFailure = data => ({
    type: t.GET_TEST_DATA_FAILURE,
    payload: data
})
export const sendTestData = ({
    type: t.SEND_TEST_DATA
})
export const sendTestDataSuccess = data => ({
    type: t.SEND_TEST_DATA_SUCCESS,
    payload: data
})
export const sendTestDataFailure = data => ({
    type: t.SEND_TEST_DATA_FAILURE,
    payload: data
})
export const getTest = (id) => {
    var data = new FormData()
    data.append('test_id', id);
    return (dispatch) => {
        dispatch(getTestData)
        return makeRequest(store.getState().user.token).post('/getTestQuestions', data).then(res => {
            if (res.data.status_code === 200) {
                dispatch(getTestDataSuccess(res.data))
            } else {
                dispatch(getTestDataFailure(res.data))
            }
        }).catch(err => {
            dispatch(getTestDataFailure({ message: "Something Went Wrong" }))
        })
    }
}

export const sendTest = (formData, history, backTo) => {
    return (dispatch) => {
        dispatch(sendTestData)
        return makeRequest(store.getState().user.token,'multipart/form-data').post('/submitTest', formData).then(res => {
            if (res.data.status_code === 200) {
                dispatch(sendTestDataSuccess(res.data))
                let back = "/home"
                if (backTo) {
                    back = "/home/writing"
                }
                history.replace(back, { message: res.data.message, celebrate: res.data.celebrate })
            } else {
                dispatch(sendTestDataFailure(res.data))
            }
        }).catch(err => {
            dispatch(sendTestDataFailure({ message: "Something went Wrong!" }))
        })
    }
}
