import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { Container, Card, Button, Form, Row } from 'react-bootstrap';
import Loader from '../components/Loader';
import makeRequest from '../utils/makeRequest';
import store from '../utils/store';
import ShowToast from '../utils/ShowToast';
import '../css/reviewtest.css'
// import '../css/test.css'
function Homework(props) {
    const { fromHome, homework_id, subject_name, week_name, subject_id } = props.location.state
    const history = useHistory()
    const [questions, setQuestions] = useState(null)
    const [loading, setLoading] = useState(false)
    const [homework, setHomework] = useState()
    const [marked, setMarked] = useState(false)
    const [submission, setSubmission] = useState()
    useEffect(() => {
        if (!fromHome) {
            history.replace('/home')
        }
        getHomework()
    }, [])
    const getHomework = () => {
        setLoading(true)
        let data = new FormData()
        data.append('homework_id', homework_id)
        makeRequest(store.getState().user.token).post('/getHomeworkQuestions', data).then(res => {
            setLoading(false)
            if (res.data.status_code == 200) {
                setSubmission(res.data.submission[0])
                setHomework(res.data.data)
            } else {
                ShowToast('error', res.data.message)
            }

        }).catch(err => {
            setLoading(false)
            ShowToast('error', "Something went wrong")
        })
    }
    let alphabets = ['A. ', 'B. ', 'C. ', 'D. ', 'E. ', 'F. ', 'G. ', 'H. ', 'I. ', 'J. ', 'K. ', 'L. ', 'M. ', 'N. ', 'O. ', 'P. ', 'Q. ', 'R. ', 'S. ', 'T. ', 'U. ', 'V. ', 'W. ', 'X. ', 'Y. ', 'Z .']
    useEffect(() => {
        if (homework && !submission) {
            let q = []
            let i = 1
            homework[0].questions.forEach(element => {
                q.push(
                    <div key={`div${element.id}`}>
                        {element.intro_text != null && <div dangerouslySetInnerHTML={{ __html: element.intro_text }}></div>}
                        {element.intro_image != null && <img src={process.env.PUBLIC_URL + element.intro_image} />}
                        <Card id={element.id} className="m-3 shadow-sm" key={element.id}>
                            <Card.Header as="h5">{`Q${i}`}</Card.Header>
                            <Card.Body>
                                {element.question != null && <div className="question" dangerouslySetInnerHTML={{ __html: element.question }}></div>}
                                {element.question_image != null && <img src={process.env.PUBLIC_URL + element.question_image} />}
                                <br />
                                {JSON.parse(element.options).map((option, index) => (
                                    option.type == "string" ?
                                        <div id={"cradio" + element.id + "-" + (index + 1)} key={`string${element.id}${index}`}>
                                            <Form.Check
                                                key={index}
                                                type='radio'
                                                id={`string${element.id}${index}`}
                                                name={element.id}
                                                value={index + 1}
                                                label={alphabets.includes(option.value + ". ") ? option.value : alphabets[index] + option.value}
                                            />
                                        </div>
                                        :
                                        <div id={"cradio" + element.id + "-" + (index + 1)} key={`image${element.id}${index}`}>
                                            <Form.Check
                                                key={index}
                                                type='radio'
                                                id={`string${element.id}${index}`}
                                                name={element.id}
                                                value={index + 1}
                                                label={<div style={{ flexDirection: "row" }}>
                                                    <div style={{ display: "inline" }}>{alphabets[index]}</div>
                                                    <img src={process.env.PUBLIC_URL + option.value} />
                                                </div>}
                                            />
                                        </div>
                                ))}
                            </Card.Body>
                        </Card>
                    </div>
                )
                i++
            });
            setQuestions(q)
        } else if (homework && submission) {
            let q = []
            let i = 1
            let answers = JSON.parse(submission.homework_data)
            homework[0].questions.forEach(element => {
                let c = ""
                if (element.answer == answers[element.id]) {
                    c = "correct"
                } else {
                    if (answers[element.id]) {
                        c = "incorrect"
                    } else {
                        c = "noanswer"
                    }
                }
                q.push(
                    <div>
                        {element.intro_text != null && <div dangerouslySetInnerHTML={{ __html: element.intro_text }}></div>}
                        {element.intro_image != null && <img src={process.env.PUBLIC_URL + element.intro_image} />}
                        <Card id={element.id} className="m-3 shadow-sm" key={element.id}>
                            <Card.Header as="h5" className={c}>{`Q${i}`} {c == "noanswer" && <p>(No Answer)</p>}</Card.Header>
                            <Card.Body>
                                {element.question != null && <div className="question" dangerouslySetInnerHTML={{ __html: element.question }}></div>}
                                {element.question_image != null && <img src={process.env.PUBLIC_URL + element.question_image} />}
                                <br />
                                {JSON.parse(element.options).map((option, index) => (
                                    option.type == "string" ?
                                        <div id={"cradio" + element.id + "-" + (index + 1)} key={`${element.id}${index}`}>
                                            <Form.Check
                                                style={{ display: "inline-block" }}
                                                key={index}
                                                type='radio'
                                                id={index + 1}
                                                name={element.id}
                                                value={index + 1}
                                                disabled={true}
                                                label={alphabets.includes(option.value + ". ") ? option.value : alphabets[index] + option.value}
                                            />
                                            {element.answer == index + 1 && <i className="fa fa-check" style={{ color: "#4caf50", marginLeft: 10 }} />}
                                            {(c == "incorrect" && answers[element.id] == index + 1) && <i className="fa fa-times" style={{ color: "#aa2e25", marginLeft: 10 }} />}
                                        </div>
                                        :
                                        <div id={"cradio" + element.id + "-" + (index + 1)} key={`${element.id}${index}`}>
                                            <Form.Check
                                                style={{ display: "inline-block" }}
                                                key={index}
                                                type='radio'
                                                id={index + 1}
                                                name={element.id}
                                                value={index + 1}
                                                disabled={true}
                                                label={<div style={{ flexDirection: "row" }}>
                                                    <div style={{ display: "inline" }}>{alphabets[index]}</div>
                                                    <img src={process.env.PUBLIC_URL + option.value} />
                                                </div>}
                                            />
                                            {element.answer == index + 1 && <i className="fa fa-check" style={{ color: "#4caf50", marginLeft: 10 }} />}
                                            {(c == "incorrect" && answers[element.id] == index + 1) && <i className="fa fa-times" style={{ color: "#aa2e25", marginLeft: 10 }} />}
                                        </div>
                                ))}
                            </Card.Body>
                        </Card>
                    </div>
                )
                i++
            });
            setQuestions(q)
        }
    }, [homework])
    const sendHomework = (formData) => {
        setLoading(true)
        makeRequest(store.getState().user.token).post('/submitHomework', formData).then(res => {
            setLoading(false)
            if (res.data.status_code === 200) {
                history.replace(`/home/subject/${subject_id}`, { message: res.data.message, celebrate: res.data.celebrate })
            } else {
                ShowToast('error', res.data.message)
            }
        }).catch(err => {
            setLoading(false)
            ShowToast('error', "Something went Wrong!")
        })
    }

    return (
        <div>
            {loading && <Loader />}
            <Container>
                <div>
                    {homework && <h3 className="text-center m-5">{`${subject_name} - Week ${week_name} - ${homework[0].name}`}</h3>}
                    <form id="test-form">
                        {questions}
                        <div className="d-flex justify-content-center">
                            {!submission &&
                                <Button className="m-3 shadow-sm" variant="primary" type="submit" onClick={(event) => {
                                    event.preventDefault()
                                    var answers = new Object();
                                    var form = document.getElementById('test-form')
                                    for (var i = 0; i < form.elements.length; i++) {
                                        var e = form.elements[i];
                                        if (e.checked) {
                                            answers[encodeURIComponent(e.name)] = encodeURIComponent(e.value)
                                            // answers.push({
                                            //     question: encodeURIComponent(e.name),
                                            //     answer: encodeURIComponent(e.value)
                                            // });
                                        }
                                    }
                                    var formData = new FormData()
                                    formData.append("id", homework_id)
                                    formData.append("data", JSON.stringify(answers))
                                    if (window.confirm("Are you sure you want to submit the homework?")) {
                                        sendHomework(formData)
                                    }
                                }}>Submit</Button>}
                        </div>
                    </form>
                </div>
            </Container>
        </div >
    )
}

export default Homework
