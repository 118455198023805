import React, { useState, useContext, useEffect } from 'react'
import { Modal, Form } from 'react-bootstrap'
import BtnDark from '../../../components/BtnDark'
import ShowToast from '../../../utils/ShowToast'
import { AdminContext } from '../../../utils/AdminProvider'
import makeRequest from '../../../utils/makeRequest'
import store from '../../../utils/store'

export default function EditGrade(props) {
    const [loading, setLoading] = useState(false)
    const [gradeName, setGradeName] = useState()
    const [gradeId, setGradeId] = useState()
    const { setGrades } = useContext(AdminContext)
    const editGradeData = () => {
        setLoading(true)
        let data = new FormData()
        data.append('id', gradeId)
        data.append('name', gradeName)
        makeRequest(store.getState().user.token).post('/editGrade', data).then(res => {
            setLoading(false)
            if (res.data.status_code === 200) {
                setGrades(res.data.data)
                props.onHide()
            } else {
                ShowToast('error', res.data.message)
            }
        }).catch(err => {
            setLoading(false)
            ShowToast('error', "Something Went Wrong!")
        })
    }
    useEffect(() => {
        if (props.item) {
            setGradeId(props.item.id)
            setGradeName(props.item.name)
        }
    }, [props.item])
    return (
        <Modal
            {...props}
            size="m"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Edit Grade
      </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="add-grade-form">
                    <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="name" placeholder="Enter Grade Name (eg. 8th Grade)" onChange={(event) => { setGradeName(event.target.value) }} value={gradeName} />
                    </Form.Group>
                    <BtnDark title="Submit" loading={loading} onClick={(event) => {
                        event.preventDefault()
                        if (gradeName != null && gradeName.trim() != "") {
                            editGradeData()
                        } else {
                            ShowToast('error', "Please enter/select all the fields")
                        }
                    }} />
                </Form>
            </Modal.Body>
        </Modal>
    )
}
