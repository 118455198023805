import React, { useEffect } from 'react'
import { Container, Card, Row, Form, Col, Spinner } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import Compress from 'compress.js'
import { upload } from '../actions/userActions'
import Loader from '../components/Loader'

function Profile() {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    useEffect(() => {
        let compress = new Compress()
        compress.attach('#file', {
            size: 4,
            quality: .75
        }).then((results) => {
            // Example mimes:
            // image/png, image/jpeg, image/jpg, image/gif, image/bmp, image/tiff, image/x-icon,  image/svg+xml, image/webp, image/xxx, image/png, image/jpeg, image/webp
            // If mime is not provided, it will default to image/jpeg
            const img1 = results[0]
            const base64str = img1.data
            const imgExt = img1.ext
            const file = Compress.convertBase64ToFile(base64str, imgExt)
            dispatch(upload(file))
        })
    }, [])
    return (
        <Container>
            <Card className="shadow rounded m-5">
                <Card.Body>
                    <Card.Title>{user.name}</Card.Title>
                    <Row className="p-3">
                        <Col>
                            {user.image == "" ?
                                <div className="initialsBig">
                                    <strong style={{ fontSize: 62 }}>{user.name.split(" ").map((n) => n[0]).join("")}</strong>
                                </div> :
                                user.isLoading ?
                                    <div className="profileImageBig">
                                        <Spinner animation="border" variant="light"/>
                                    </div> : <img src={user.image} className="profileImageBig" key={user.image} />
                            }
                        </Col>
                        <Col>
                            <Form>
                                <Form.Group>
                                    <Form.File id="file" label="Choose Image" onChange={(event) => { }} />
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Container>
    )
}

export default Profile
