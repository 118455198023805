import React from 'react'
import { Row, Button } from 'react-bootstrap'

export default function NextPrev({ page, setPage, monitor }) {

    return (
        <Row>
            <Button
                disabled={page == 0 ? true : false}
                onClick={() => {
                    setPage(page - 1)
                }}
                style={{ margin: 4 }}
                variant="outline-dark">
                <i className="fa fa-chevron-left" />
            </Button>
            <Button
                disabled={monitor.length < 10 ? true : false}
                onClick={() => {
                    setPage(page + 1)
                }}
                style={{ margin: 4 }}
                variant="outline-dark">
                <i className="fa fa-chevron-right" />
            </Button>
        </Row>
    )
}
