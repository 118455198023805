import React, { useState, useEffect } from 'react'
import { Modal, Button, Form, Spinner } from 'react-bootstrap'
import { AdminContext } from '../../../utils/AdminProvider'
import makeRequest from '../../../utils/makeRequest'
import store from '../../../utils/store'
import ShowToast from '../../../utils/ShowToast'

function EditTutor(props) {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [loading, setLoading] = useState(false)
    const { generateErrors, setTutors } = React.useContext(AdminContext)
    const validate = () => {
        if (!name || name.trim() === "") {
            ShowToast('error', "Please enter name")
            return
        }
        if (!username || username.trim() === "") {
            ShowToast('error', "Please enter username")
            return
        }
        if (!email || email.trim() === "") {
            ShowToast('error', "Please enter email")
            return
        }
        let data = new FormData()
        if (password || password.trim() !== "") {
            if (password !== confirmPassword) {
                ShowToast('error', "Passwords dont match")
                return
            } else {
                data.append('password', password)
                data.append('confirm_password', confirmPassword)
            }
        }
        data.append('name', name)
        data.append('username', username)
        data.append('email', email)
        data.append('id', props.item.id)
        updateTutor(data)
    }
    const updateTutor = (data) => {
        setLoading(true)
        makeRequest(store.getState().user.token).post('/updateTutor', data).then(res => {
            setLoading(false)
            if (res.data.status_code == 200) {
                ShowToast('success', res.data.message)
                setTutors(res.data.data)
                props.onHide()
            } else {
                ShowToast('error', res.data.message)
                generateErrors(res.data.errors)
            }
        }).catch(err => {
            setLoading(false)
            ShowToast('error', 'Something went wrong')
        })
    }
    useEffect(() => {
        if (props.item) {
            let item = props.item
            setName(item.name)
            setEmail(item.email)
            setUsername(item.username)
        }
    }, [props.item])
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Edit Tutor
          </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="add-form">
                    <Form.Group >
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="name" placeholder="Enter Name" onChange={(event) => { setName(event.target.value) }} value={name} />
                    </Form.Group>
                    <Form.Group >
                        <Form.Label>Username</Form.Label>
                        <Form.Control type="username" placeholder="Enter username" onChange={(event) => { setUsername(event.target.value) }} value={username} />
                    </Form.Group>
                    <Form.Group >
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Enter Email" onChange={(event) => { setEmail(event.target.value) }} value={email} />
                    </Form.Group>
                    <Form.Group >
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" onChange={(event) => { setPassword(event.target.value) }} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" onChange={(event) => { setConfirmPassword(event.target.value) }} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {loading ? <Spinner animation="border" variant="dark" /> : <Button onClick={() => validate()}>Save</Button>}
                <Button onClick={props.onHide} variant="dark">Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default EditTutor
