import React, { useState } from 'react'
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import { useHistory, useLocation } from 'react-router-dom';

function SideMenu() {
    const history = useHistory()
    const location = useLocation()
    const [expanded, setExpanded] = useState(false)

    return (
        <SideNav
            onMouseEnter={() => { setExpanded(true) }}
            onMouseLeave={() => { setExpanded(false) }}
            onToggle={() => { }}
            expanded={expanded}
            onSelect={(selected) => {
                // Add your code here
                history.push(selected)
            }} style={{ backgroundColor: "#292b2c", position: "fixed" }}>
            <SideNav.Toggle />
            <SideNav.Nav defaultSelected={location.pathname}>
                <NavItem eventKey="/admin">
                    <NavIcon>
                        <i className="fa fa-fw fa-home" style={{ fontSize: '1.75em', verticalAlign: "middle", color: "#fff" }} />
                    </NavIcon>
                    <NavText style={{ color: "#fff" }}>Home</NavText>
                </NavItem>
                <NavItem eventKey="/admin/grade">
                    <NavIcon>
                        <i className="fa fa-fw fa-list-ol sidemenuicon" style={{ fontSize: '1.75em', verticalAlign: "middle", color: "#fff" }} />
                    </NavIcon>
                    <NavText style={{ color: "#fff" }}>Grades</NavText>
                </NavItem>
                <NavItem eventKey="/admin/subject">
                    <NavIcon>
                        <i className="fa fa-fw fa-flask sidemenuicon" style={{ fontSize: '1.75em', verticalAlign: "middle", color: "#fff" }} />
                    </NavIcon>
                    <NavText style={{ color: "#fff" }}>Subjects</NavText>
                </NavItem>
                <NavItem eventKey="/admin/test">
                    <NavIcon>
                        <i className="fa fa-fw fa-file-text sidemenuicon" style={{ fontSize: '1.75em', verticalAlign: "middle", color: "#fff" }} />
                    </NavIcon>
                    <NavText style={{ color: "#fff" }}>Tests</NavText>
                </NavItem>
                <NavItem eventKey="/admin/homework">
                    <NavIcon>
                        <i className="fa fa-fw fa-book sidemenuicon" style={{ fontSize: '1.75em', verticalAlign: "middle", color: "#fff" }} />
                    </NavIcon>
                    <NavText style={{ color: "#fff" }}>Homework</NavText>
                </NavItem>
                <NavItem eventKey="/admin/student">
                    <NavIcon>
                        <i className="fa fa-fw fa-user-circle sidemenuicon" style={{ fontSize: '1.75em', verticalAlign: "middle", color: "#fff" }} />
                    </NavIcon>
                    <NavText style={{ color: "#fff" }}>Students</NavText>
                </NavItem>
                <NavItem eventKey="/admin/tutors">
                    <NavIcon>
                        <i className="fa fa-fw fa-male sidemenuicon" style={{ fontSize: '1.75em', verticalAlign: "middle", color: "#fff" }} />
                    </NavIcon>
                    <NavText style={{ color: "#fff" }}>Tutors</NavText>
                </NavItem>
                <NavItem eventKey="/admin/enroll">
                    <NavIcon>
                        <i className="fa fa-fw fa-list-alt sidemenuicon" style={{ fontSize: '1.75em', verticalAlign: "middle", color: "#fff" }} />
                    </NavIcon>
                    <NavText style={{ color: "#fff" }}>Enrollment Form</NavText>
                </NavItem>
                <NavItem eventKey="/admin/equation">
                    <NavIcon>
                        <i className="fa fa-fw fa-superscript sidemenuicon" style={{ fontSize: '1.75em', verticalAlign: "middle", color: "#fff" }} />
                    </NavIcon>
                    <NavText style={{ color: "#fff" }}>Equation Generator</NavText>
                </NavItem>
                <NavItem eventKey="/admin/billing">
                    <NavIcon>
                        <i className="fa fa-fw fa-money sidemenuicon" style={{ fontSize: '1.75em', verticalAlign: "middle", color: "#fff" }} />
                    </NavIcon>
                    <NavText style={{ color: "#fff" }}>Billing</NavText>
                </NavItem>
                {/* <div style={{ marginTop: 24 }}></div> */}
                {/* <NavItem eventKey="/logout">
                    <NavIcon>
                        <i className="fa fa-fw fa-sign-out sidemenuicon" style={{ fontSize: '1.75em', verticalAlign: "middle", color: "#fff" }} />
                    </NavIcon>
                    <NavText style={{ color: "#fff" }}>Logout</NavText>
                </NavItem> */}
                {/* <NavItem eventKey="charts">
                    <NavIcon>
                        <i className="fa fa-fw fa-line-chart" style={{ fontSize: '1.75em' }} />
                    </NavIcon>
                    <NavText>
                        Charts
            </NavText>
                    <NavItem eventKey="charts/linechart">
                        <NavText>
                            Line Chart
                </NavText>
                    </NavItem>
                    <NavItem eventKey="charts/barchart">
                        <NavText>
                            Bar Chart
                </NavText>
                    </NavItem>
                </NavItem> */}
            </SideNav.Nav>
        </SideNav>
    )
}

export default SideMenu
