import React from 'react'
import { Modal, Row } from 'react-bootstrap'
import Chip from '../../../components/Chip'
function ViewComment(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">feedback</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: 400, overflow: "scroll" }}>
                <strong>Students</strong>
                <Row style={{ padding: "0px 8px 0px 8px" }}>
                    {props.item?.feedback.comments ? JSON.parse(props.item?.feedback.students).map((item, index) => (
                        <Chip item={item} disabled key={`chip${index}`}/>
                    ))
                        : null
                    }
                </Row>
                <pre style={{ whiteSpace: "pre-wrap" }}>{props.item?.feedback.comments}</pre>
            </Modal.Body>
        </Modal>
    )
}

export default ViewComment