import React, { useState } from 'react'
import { Card, Container } from 'react-bootstrap'
import { MathfieldComponent } from 'react-mathlive'

function EquationGenerator() {
    const [latex, setLatex] = useState("")
    return (
        <Container className="ccontainer">
            <div className="d-flex flex-column mt-3 align-items-center">
                <Card style={{ width: "80%" }}>
                    <Card.Body>
                        <Card.Title>Equation Generator</Card.Title>
                        <div style={{ border: "1px black solid" }}>
                            <MathfieldComponent
                                latex={latex}
                                onChange={setLatex}
                                mathfieldConfig={{
                                    virtualKeyboardMode: "onfocus",
                                    virtualKeyboardTheme: "material",
                                    editingOptions: {
                                        ignoreSpacebarInMathMode: true,
                                    }
                                }}
                            />
                        </div>
                        <div className="mt-3">
                            {latex}
                        </div>
                    </Card.Body>
                </Card>
            </div>

        </Container>
    )
}

export default EquationGenerator
