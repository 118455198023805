import React, { useState, useEffect } from 'react'
import ShowToast from './ShowToast'
import store from './store'
import makeRequest from './makeRequest'
export const AdminContext = React.createContext()
export function AdminProvider(props) {
    const [loading, setLoading] = useState(false)
    const [grades, setGrades] = useState([])
    const [students, setStudents] = useState([])
    const [subjects, setSubjects] = useState([])
    const [tests, setTests] = useState([])
    const [homework, setHomework] = useState([])
    const [tutors, setTutors] = useState([])
    const generateErrors = (data) => {
        for (var key in data) {
            data[key].map((item, index) => {
                ShowToast('error', item)
            })
        }
    }
    const getGradeData = () => {
        makeRequest(store.getState().user.token).get('/getGradesList').then(res => {
            if (res.data.status_code === 200) {
                setGrades(res.data.data)
            } else {
                ShowToast('error', res.data.message)
            }
        }).catch(err => {
            ShowToast('error', "Something Went Wrong!")
        })
    }
    const getStudents = (grade, type, name, page) => {
        let data = new FormData()
        if (grade && grade !== null) {
            data.append('grade_id', grade)
        }
        if (type && type !== null) {
            data.append('type', type)
        }
        if (name && name.trim() !== "") {
            data.append('name', name)
        }
        data.append('page', page)
        // if (grade == null && type == null && !name && name.trim() == "") {
        //     ShowToast('error', "Please select atleast one filter")
        //     return
        // }
        setLoading(true)
        makeRequest(store.getState().user.token).post('/getStudents', data).then(res => {
            setLoading(false)
            if (res.data.status_code === 200) {
                setStudents(res.data.data)
            } else {
                ShowToast('error', res.data.msg)
            }
        }).catch(err => {
            setLoading(false)
            ShowToast('error', 'Something went wrong')
        })
    }
    const getSubjectData = () => {
        makeRequest(store.getState().user.token).get('/getSubjectsList').then(res => {
            if (res.data.status_code === 200) {
                setSubjects(res.data.data)
            } else {
                ShowToast('error', res.data.message)
            }
        }).catch(err => {
            ShowToast('error', "Something Went Wrong!")
        })
    }
    const getTestsData = () => {
        makeRequest(store.getState().user.token).get('/getTestsList').then(res => {
            if (res.data.status_code === 200) {
                setTests(res.data.data)
            } else {
                ShowToast('error', res.data.message)
            }
        }).catch(err => {
            ShowToast('error', "Something Went Wrong!")
        })
    }
    const getHomeworkData = () => {
        setLoading(true)
        makeRequest(store.getState().user.token).get('/getHomeworkList').then(res => {
            setLoading(false)
            if (res.data.status_code === 200) {
                setHomework(res.data.data)
            } else {
                ShowToast('error', res.data.message)
            }
        }).catch(err => {
            setLoading(false)
            ShowToast('error', "Something Went Wrong!")
        })
    }
    useEffect(() => {
        getGradeData()
        getSubjectData()
        getTestsData()
        getHomeworkData()
    }, [])
    const updateStudentStatus = (student_id, value) => {
        setLoading(true)
        let data = new FormData()
        data.append("student_id", student_id)
        data.append("status", value)
        makeRequest(store.getState().user.token).post("/updateStudentStatus", data).then(res => {
            if (res.data.status_code == 200) {
                ShowToast("success", res.data.message)
                let newStudents = []
                students.forEach(element => {
                    if (element.id == student_id) {
                        if (value) {
                            element.active = 1
                        } else {
                            element.active = 0
                        }
                    }
                    newStudents.push(element)
                });
                setStudents(newStudents)
            } else {
                ShowToast("error", res.data.message)
            }
        }).catch(err => {
            ShowToast("error", "Something went wrong!")
        }).finally(() => {
            setLoading(false)
        })

    }
    return (
        <AdminContext.Provider value={{
            generateErrors,
            loading,
            getGradeData,
            setGrades,
            grades,
            getStudents,
            setStudents,
            students,
            getSubjectData,
            setSubjects,
            subjects,
            getTestsData,
            setTests,
            tests,
            getHomeworkData,
            setHomework,
            homework,
            tutors,
            setTutors,
            updateStudentStatus
        }}>
            {props.children}
        </AdminContext.Provider>
    )
}

