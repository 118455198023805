import React, { useState, useContext, useEffect } from 'react'
import { Container, Form, Table } from 'react-bootstrap'
import { AdminContext } from '../../utils/AdminProvider'
import FAB from '../../components/FAB'
import BtnDark from '../../components/BtnDark'
import AddSubject from './Subjects/AddSubject'
import EditSubject from './Subjects/EditSubject'

export default function Subjects() {
    const { subjects, grades } = useContext(AdminContext)
    const [s, setS] = useState([])
    const [name, setName] = useState("")
    const [gradeId, setGradeId] = useState(null)
    const [selected, setSelected] = useState()
    const [show, setShow] = useState(false)
    const [edit, setEdit] = useState(false)
    const filter = () => {
        if (gradeId || name.length > 0) {
            let list = subjects.filter(value => {
                if (name.length > 0) {
                    if (gradeId != null) {
                        if (value.name.indexOf(name) > -1 && value.grade_id == gradeId) {
                            return true
                        }
                    } else {
                        if (value.name.indexOf(name) > -1) {
                            return true
                        }
                    }
                } else if (value.grade_id == gradeId) {
                    return true
                }
                return false
            })
            setS(list)
        } else {
            setS([...subjects])
        }
    }
    const clear = () => {
        setS([...subjects])
    }
    useEffect(() => {
        if (subjects.length > 0) {
            filter()
        }
    }, [subjects])
    return (
        <Container>
            <AddSubject
                show={show}
                onHide={() => { setShow(false) }}
            />
            <EditSubject
                show={edit}
                onHide={() => { setEdit(false) }}
                item={selected} />
            <div className="d-flex flex-column mt-3 align-items-center">
                <Form>
                    <Form.Row>
                        <Form.Group >
                            <Form.Control placeholder="Subject name" value={name} onChange={(event) => { setName(event.target.value) }} />
                        </Form.Group>
                        <Form.Group style={{ marginLeft: 16 }}>
                            <Form.Control as="select" onChange={(event) => { setGradeId(event.target.value) }} placeholder="Grade" >
                                <option value={null}>Select Grade</option>
                                {grades.map(item => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <div style={{ marginLeft: 16 }}>
                            <BtnDark onClick={(event) => {
                                event.preventDefault()
                                filter()
                            }} title="Search" />
                        </div>
                        <div style={{ marginLeft: 16 }}>
                            <BtnDark onClick={(event) => {
                                event.preventDefault()
                                clear()
                            }} title="Clear" />
                        </div>
                    </Form.Row>
                </Form>
                <Table striped bordered hover variant="dark">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Grade</th>
                            <th className="text-center">options</th>
                        </tr>
                    </thead>
                    <tbody>
                        {s.map((item, index) => (

                            <tr key={String(index)}>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{grades.find(x => x.id === item.grade_id).name}</td>
                                <td >
                                    <i
                                        className="fa fa-edit"
                                        onClick={() => {
                                            setSelected(item)
                                            setEdit(true)
                                        }} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            <FAB onPress={() => { setShow(true) }} />
        </Container >
    )
}
