import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { Container, Card, Button, Form, Row } from 'react-bootstrap';
import moment from 'moment';
import { getTest, sendTest } from '../actions/testActions';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/Loader';
import { toast } from 'react-toastify';
import '../css/reviewtest.css'
import '../css/test.css'
import { MathfieldComponent } from 'react-mathlive'
import { url } from '../utils/Constants';

function Test(props) {
    const { fromHome, test_id, submission, subject_name, backTo } = props.location.state
    const history = useHistory()
    const dispatch = useDispatch()
    const test = useSelector(state => state.test)
    const [questions, setQuestions] = useState(null)
    const [marked, setMarked] = useState(false)
    const [file, setFile] = useState()
    useEffect(() => {
        if (!fromHome) {
            history.replace('/home')
        }
        // if (!submission) {
        dispatch(getTest(test_id))
        // }
    }, [])
    let alphabets = ['A. ', 'B. ', 'C. ', 'D. ', 'E. ', 'F. ', 'G. ', 'H. ', 'I. ', 'J. ', 'K. ', 'L. ', 'M. ', 'N. ', 'O. ', 'P. ', 'Q. ', 'R. ', 'S. ', 'T. ', 'U. ', 'V. ', 'W. ', 'X. ', 'Y. ', 'Z .']
    useEffect(() => {
        if (test.data[0] && !submission) {
            let q = []
            let i = 1
            test.data[0].questions.forEach(element => {
                q.push(
                    <div key={`div${element.id}`}>
                        {element.intro_text != null && <div className="m-3 " dangerouslySetInnerHTML={{ __html: element.intro_text }}></div>}
                        {element.intro_image != null && <img className="m-3 " src={process.env.PUBLIC_URL + element.intro_image} />}
                        <Card id={element.id} className="m-3 shadow-sm" key={element.id} >
                            <Card.Header as="h5">{`Q${i}`}</Card.Header>
                            <Card.Body>
                                {element.question != null && <div className="question" dangerouslySetInnerHTML={{ __html: element.question }}></div>}
                                {element.question_image != null && <img src={process.env.PUBLIC_URL + element.question_image} />}
                                <br />
                                {element.mcq == 1 ?
                                    JSON.parse(element.options).map((option, index) => (
                                        option.type == "string" ?
                                            <div id={"cradio" + element.id + "-" + (index + 1)} key={`${element.id}${index}`}>
                                                <Form.Check
                                                    key={index}
                                                    type='radio'
                                                    id={`${element.id}${index}`}
                                                    name={element.id}
                                                    value={index + 1}
                                                    label={alphabets.includes(option.value + ". ") ? option.value : alphabets[index] + option.value}
                                                />
                                            </div>
                                            :
                                            <div id={"cradio" + element.id + "-" + (index + 1)} key={`${element.id}${index}`} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                <Form.Check
                                                    key={index}
                                                    type='radio'
                                                    id={`${element.id}${index}`}
                                                    aria-label={option.value}
                                                    name={element.id}
                                                    value={index + 1}
                                                />
                                                <div style={{ flexDirection: "row" }}>
                                                    <div style={{ display: "inline" }}>{alphabets[index]}</div>
                                                    <img src={process.env.PUBLIC_URL + option.value} />
                                                </div>
                                            </div>
                                    )) :
                                    element.mcq == 2 ?
                                        <div>
                                            <Form.Group>
                                                <Form.File
                                                    name={element.id}
                                                    id={`input${element.id}`}
                                                    accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                    id="exampleFormControlFile1"
                                                    label="Upload your file"
                                                    onChange={e => {
                                                        setFile({
                                                            question_id: element.id,
                                                            file: e.target.files[0]
                                                        })
                                                    }} />
                                            </Form.Group>
                                        </div>
                                        :
                                        <div style={{ border: "1px solid black" }} key={element.id}>
                                            <input name={element.id} id={`input${element.id}`} style={{ display: "none" }} />
                                            <MathfieldComponent
                                                // latex={latex}
                                                onChange={(value) => fillInput(element.id, value)}
                                                mathfieldConfig={{
                                                    virtualKeyboardMode: "onfocus",
                                                    virtualKeyboardTheme: "material",
                                                    editingOptions: {
                                                        ignoreSpacebarInMathMode: true,
                                                    }
                                                }}
                                            />
                                        </div>
                                }
                            </Card.Body>
                        </Card>
                    </div>
                )
                i++
            });
            setQuestions(q)
        } else if (test.data[0] && submission) {
            let q = []
            let i = 1
            let answers = JSON.parse(submission.test_data)
            test.data[0].questions.forEach(element => {
                let c = ""
                if (element.answer == answers[element.id]) {
                    c = "correct"
                } else {
                    if (answers[element.id]) {
                        c = "incorrect"
                    } else {
                        c = "noanswer"
                    }
                }
                q.push(
                    <div>
                        {element.intro_text != null && <div dangerouslySetInnerHTML={{ __html: element.intro_text }}></div>}
                        {element.intro_image != null && <img src={process.env.PUBLIC_URL + element.intro_image} />}
                        <Card id={element.id} className="m-3 shadow-sm" key={element.id}>
                            <Card.Header as="h5" className={element.mcq === 2 ? null : c}>{`Q${i}`} {c == "noanswer" && <p>(No Answer)</p>}</Card.Header>
                            <Card.Body>
                                {element.question != null && <div className="question" dangerouslySetInnerHTML={{ __html: element.question }}></div>}
                                {element.question_image != null && <img src={process.env.PUBLIC_URL + element.question_image} />}
                                <bt />
                                {element.mcq == 1 ?
                                    JSON.parse(element.options).map((option, index) => (
                                        option.type == "string" ?
                                            <div id={"cradio" + element.id + "-" + (index + 1)} key={`${element.id}${index}`}>
                                                <Form.Check
                                                    style={{ display: "inline-block" }}
                                                    key={index}
                                                    type='radio'
                                                    id={index + 1}
                                                    name={element.id}
                                                    value={index + 1}
                                                    disabled={true}
                                                    label={alphabets.includes(option.value + ". ") ? option.value : alphabets[index] + option.value}
                                                />
                                                {element.answer == index + 1 && <i className="fa fa-check" style={{ color: "#4caf50", marginLeft: 10 }} />}
                                                {(c == "incorrect" && answers[element.id] == index + 1) && <i className="fa fa-times" style={{ color: "#aa2e25", marginLeft: 10 }} />}
                                            </div>
                                            :
                                            <div id={"cradio" + element.id + "-" + (index + 1)} key={`${element.id}${index}`}>
                                                <Form.Check
                                                    style={{ display: "inline-block" }}
                                                    key={index}
                                                    type='radio'
                                                    id={index + 1}
                                                    name={element.id}
                                                    value={index + 1}
                                                    disabled={true}
                                                    label={<div style={{ flexDirection: "row" }}>
                                                        <div style={{ display: "inline" }}>{alphabets[index]}</div>
                                                        <img src={process.env.PUBLIC_URL + option.value} />
                                                    </div>}
                                                />
                                                {element.answer == index + 1 && <i className="fa fa-check" style={{ color: "#4caf50", marginLeft: 10 }} />}
                                                {(c == "incorrect" && answers[element.id] == index + 1) && <i className="fa fa-times" style={{ color: "#aa2e25", marginLeft: 10 }} />}
                                            </div>
                                    )) :
                                    element.mcq === 2 ?
                                        <div>
                                            <a href={url + answers[element.id]} download>Download</a>
                                        </div>
                                        :
                                        <div>
                                            <div style={{ flexDirection: "row", width: "50%" }} key={element.id}>
                                                <div style={{ width: "30%", display: "inline-block" }}>
                                                    <MathfieldComponent
                                                        latex={answers[element.id]}
                                                        mathfieldConfig={{
                                                            readOnly: true,
                                                            editingOptions: {
                                                                ignoreSpacebarInMathMode: true,
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                {element.answer == answers[element.id] && <i className="fa fa-check" style={{ color: "#4caf50", marginLeft: 10 }} />}
                                                {(c == "incorrect" && answers[element.id] == answers[element.id]) && <i className="fa fa-times" style={{ color: "#aa2e25", marginLeft: 10 }} />}
                                            </div>
                                            {(c == "incorrect" || c == "noanswer") ?
                                                <div style={{ flexDirection: "row", width: "50%" }} key={element.id}>
                                                    <strong>Correct answer:</strong>
                                                    <div style={{ width: "30%" }}>
                                                        <MathfieldComponent
                                                            latex={element.answer}
                                                            mathfieldConfig={{
                                                                readOnly: true,
                                                                editingOptions: {
                                                                    ignoreSpacebarInMathMode: true,
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                : null}
                                        </div>
                                }
                            </Card.Body>
                        </Card>
                    </div>
                )
                i++
            });
            setQuestions(q)

        }
    }, [test.data])
    useEffect(() => {
        if (test.error) {
            toast.error(test.message, {
                autoClose: false,
                position: "top-right",
                progress: undefined,
                draggable: true,
                closeOnClick: true
            })
        }
    }, [test.error])
    const getImage = (type) => {
        switch (type) {
            case "Astrophile":
                return require('../assets/testImages/Astrophile.jpg')
            case "Balter":
                return require('../assets/testImages/balter.png')
            case "Bel-esprit":
                return require('../assets/testImages/bel-esprit.jpg')
            case "Caim":
                return require('../assets/testImages/caim.png')
            case "Clinquant":
                return require('../assets/testImages/Clinquant.png')
            case "Insouciant":
                return require('../assets/testImages/insouciant.jpg')
            case "Komorebi":
                return require('../assets/testImages/komorebi.jpg')
            case "Metanoia":
                return require('../assets/testImages/metanoia.png')
            case "Moonglade":
                return require('../assets/testImages/moon glade.jpg')
            case "Novaturient":
                return require('../assets/testImages/novaturient.png')
            case "Orenda":
                return require('../assets/testImages/orenda.png')
            case "Saorsa":
                return require('../assets/testImages/Saorsa.jpg')
            case "Yugen":
                return require('../assets/testImages/yugen.JPG')
            default:
                return null
        }
    }
    const fillInput = (id, value) => {
        document.getElementById(`input${id}`).value = value;
    }
    return (
        <div>
            {test.loading && <Loader />}
            <Container>
                {test.data[0] && <h3 className="text-center m-5 testheading">{subject_name}</h3>}
                {test.data[0] && getImage(test.data[0].name) != null &&
                    <div className="text-center">
                        <img src={getImage(test.data[0].name)} />
                    </div>
                }
                {submission ?
                    <div>
                        <Card className="mt-5">
                            <Card.Header as="h5">Test Report</Card.Header>
                            <Card.Body>
                                <Card.Text>{`Submitted on: ${moment(submission.created_at).format('MMMM Do YYYY, h:mm:ss a')}`}</Card.Text>
                                {submission.score === 0 ?null : <Card.Title>{`Score: ${submission.score}`}</Card.Title>}
                                <Button variant="primary" onClick={() => { history.replace('/home') }}>Go Back</Button>
                            </Card.Body>
                        </Card>
                        {questions}
                    </div>
                    :
                    <div>
                        <form id="test-form">
                            {questions}
                            <div className="d-flex justify-content-center">
                                {!submission &&
                                    <Button className="m-3 shadow-sm" variant="success" type="submit" onClick={(event) => {
                                        event.preventDefault()
                                        var answers = new Object();
                                        var form = document.getElementById('test-form')
                                        for (var i = 0; i < form.elements.length; i++) {
                                            var e = form.elements[i];
                                            if (e.type == "radio") {
                                                if (e.checked) {
                                                    answers[encodeURIComponent(e.name)] = e.value
                                                    // answers.push({
                                                    //     question: encodeURIComponent(e.name),
                                                    //     answer: encodeURIComponent(e.value)
                                                    // });
                                                }
                                            }
                                            else {
                                                if (e.value != "") {
                                                    answers[encodeURIComponent(e.name)] = e.value
                                                }
                                            }
                                        }
                                        var formData = new FormData()
                                        formData.append("id", test_id)
                                        formData.append("data", JSON.stringify(answers))
                                        if (file) {
                                            formData.append("question_id", file.question_id)
                                            formData.append("upload", file.file)
                                        }
                                        if (window.confirm("Are you sure you want to submit the test?")) {
                                            dispatch(sendTest(formData, history, backTo))
                                        }
                                    }}>Submit</Button>}
                            </div>
                        </form>
                        {test.data[0]?.questions[0].mcq == 0 ?
                            <div style={{ paddingBottom: "30%" }}></div> : null}
                    </div>
                }
            </Container>
        </div >
    )
}

export default Test
