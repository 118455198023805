import React, { useContext, useState, useEffect } from 'react'
import { Form, Col, Table, Container, Spinner } from 'react-bootstrap'
import { AdminContext } from '../../utils/AdminProvider'
import BtnDark from '../../components/BtnDark'
import EditStudent from './Students/EditStudent'
import FAB from '../../components/FAB'
import AddTutor from './Tutors/AddTutor'
import makeRequest from '../../utils/makeRequest'
import store from '../../utils/store'
import ShowToast from '../../utils/ShowToast'
import EditTutor from './Tutors/EditTutor'
function Tutors() {
    const { tutors, setTutors, students } = useContext(AdminContext)
    const [name, setName] = useState("")
    const [show, setShow] = useState(false)
    const [selected, setSelected] = useState()
    const [addTutorDialog, setAddTutorDialog] = useState(false)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        getTutors()
    }, [])

    const getTutors = (name) => {
        let data = new FormData()
        if (name) {
            data.append('search', true)
            data.append('name', name)
        } else {
            data.append('search', false)
        }
        setLoading(true)
        makeRequest(store.getState().user.token).post('/getTutors', data).then(res => {
            setLoading(false)
            if (res.data.status_code === 200) {
                setTutors(res.data.data)
            } else {
                ShowToast('error', res.data.msg)
            }
        }).catch(err => {
            setLoading(false)
            ShowToast('error', 'Something went wrong')
        })
    }
    return (
        <Container>
            <div className="d-flex flex-column justify-content-center mt-3">
                <EditTutor
                    show={show}
                    onHide={() => { setShow(false) }}
                    item={selected} />
                <AddTutor
                    show={addTutorDialog}
                    onHide={() => { setAddTutorDialog(false) }}
                />
                <Form id="view-tests-form" style={{ alignSelf: "center" }}>
                    <Form.Row>
                        <Form.Group controlId="name" as={Col}>
                            <Form.Control type="name" placeholder="Enter Name" onChange={(event) => { setName(event.target.value) }} />
                        </Form.Group>
                        <BtnDark
                            title="Submit"
                            onClick={(event) => {
                                event.preventDefault()
                                getTutors(name)
                            }} />
                        <div style={{ marginLeft: 8 }}>
                            <BtnDark
                                title="Clear"
                                onClick={(event) => {
                                    event.preventDefault()
                                    getTutors()
                                }} />
                        </div>
                    </Form.Row>
                </Form>
                <div className="d-flex justify-content-center">
                    {loading && <Spinner animation="border" variant="dark" className="m-5" />}
                </div>
                {!loading &&
                    <Table striped bordered hover variant="dark">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Username</th>
                                <th>Email</th>
                                <th className="text-center">options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tutors?.map((item, index) => {
                                return (
                                    <tr key={String(index)}>
                                        <td>{item.id}</td>
                                        <td>{item.name}</td>
                                        <td>{item.username}</td>
                                        <td>{item.email}</td>
                                        <td>
                                            <i
                                                className="fa fa-edit"
                                                onClick={() => {
                                                    setSelected(item)
                                                    setShow(true)
                                                }} />
                                            <a href={`/view/tutor/${item.id}`} target="_blank">
                                                <i className="fa fa-eye ml-3" style={{ color: "#fff" }} />
                                            </a>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>}
            </div>
            <FAB onPress={() => { setAddTutorDialog(true) }} />
        </Container>
    )
}

export default Tutors