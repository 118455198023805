import React, { useEffect, useState } from 'react'
import makeRequest from '../../../utils/makeRequest'
import store from '../../../utils/store'
import ShowToast from '../../../utils/ShowToast'
import { Card, Form, Container, Button } from 'react-bootstrap'
import moment from 'moment'
import Loader from '../../../components/Loader'
function ViewSubmission(props) {
    const [questions, setQuestions] = useState()
    const [submission, setSubmission] = useState()
    useEffect(() => {
        let id = props.match.params.id
        let submission_id = props.match.params.submissionid
        if (id && submission_id) {
            let data = new FormData()
            data.append("id", id)
            data.append("submission_id", submission_id)
            data.append("type", props.match.params.type)
            makeRequest(store.getState().user.token).post('/getSubmission', data).then(res => {
                if (res.data.status_code == 200) {
                    setSubmission(res.data.submission)
                    generateReview(res.data.data, res.data.submission)
                } else {
                    ShowToast("error", res.data.message)
                }
            }).catch(err => {
                ShowToast('error', "Something went wrong!")
            })
        }
    }, [props.match.params.testid])
    let alphabets = ['A. ', 'B. ', 'C. ', 'D. ', 'E. ', 'F. ', 'G. ', 'H. ', 'I. ', 'J. ', 'K. ', 'L. ', 'M. ', 'N. ', 'O. ', 'P. ', 'Q. ', 'R. ', 'S. ', 'T. ', 'U. ', 'V. ', 'W. ', 'X. ', 'Y. ', 'Z .']
    const generateReview = (questions, submission) => {
        let q = []
        let i = 1
        let answers = props.match.params.type == "test" ? JSON.parse(submission.test_data) : JSON.parse(submission.homework_data)
        questions.forEach(element => {
            let c = ""
            if (element.answer == answers[element.id]) {
                c = "correct"
            } else {
                if (answers[element.id]) {
                    c = "incorrect"
                } else {
                    c = "noanswer"
                }
            }
            q.push(
                <div key={`${element.id}`}>
                    {element.intro_text != null && <div dangerouslySetInnerHTML={{ __html: element.intro_text }}></div>}
                    {element.intro_image != null && <img src={process.env.PUBLIC_URL + element.intro_image} />}
                    <Card id={element.id} className="m-3 shadow-sm" key={element.id}>
                        <Card.Header as="h5" className={c}>{`Q${i}`} {c == "noanswer" && <p>(No Answer)</p>}</Card.Header>
                        <Card.Body>
                            {element.question != null && <div className="question" dangerouslySetInnerHTML={{ __html: element.question }}></div>}
                            {element.question_image != null && <img src={process.env.PUBLIC_URL + element.question_image} />}
                            <br />
                            {JSON.parse(element.options).map((option, index) => (
                                option.type == "string" ?
                                    <div id={"cradio" + element.id + "-" + (index + 1)} key={`${element.id}${index}`}>
                                        <Form.Check
                                            style={{ display: "inline-block" }}
                                            key={index}
                                            type='radio'
                                            id={index + 1}
                                            name={element.id}
                                            value={index + 1}
                                            disabled={true}
                                            label={alphabets.includes(option.value + ". ") ? option.value : alphabets[index] + option.value}
                                        />
                                        {element.answer == index + 1 && <i className="fa fa-check" style={{ color: "#4caf50", marginLeft: 10 }} />}
                                        {(c == "incorrect" && answers[element.id] == index + 1) && <i className="fa fa-times" style={{ color: "#aa2e25", marginLeft: 10 }} />}
                                    </div>
                                    :
                                    <div id={"cradio" + element.id + "-" + (index + 1)} key={`${element.id}${index}`}>
                                        <Form.Check
                                            style={{ display: "inline-block" }}
                                            key={index}
                                            type='radio'
                                            id={index + 1}
                                            name={element.id}
                                            value={index + 1}
                                            disabled={true}
                                            label={<div style={{ flexDirection: "row" }}>
                                                <div style={{ display: "inline" }}>{alphabets[index]}</div>
                                                <img src={process.env.PUBLIC_URL + option.value} />
                                            </div>}
                                        />
                                        {element.answer == index + 1 && <i className="fa fa-check" style={{ color: "#4caf50", marginLeft: 10 }} />}
                                        {(c == "incorrect" && answers[element.id] == index + 1) && <i className="fa fa-times" style={{ color: "#aa2e25", marginLeft: 10 }} />}
                                    </div>
                            ))}
                        </Card.Body>
                    </Card>
                </div>
            )
            i++
        });
        setQuestions(q)
    }
    return (
        <div>
            {!submission ? <Loader /> : null}
            <Container>
                {submission ?
                    <Card className="mt-5">
                        <Card.Header as="h5" style={{textTransform:"capitalize"}}>{`${props.match.params.type} Report`}</Card.Header>
                        <Card.Body>
                            <Card.Text>{`Submitted on: ${moment(submission.created_at).format('MMMM Do YYYY, h:mm:ss a')}`}</Card.Text>
                            <Card.Title>{`Score: ${submission.score}`}</Card.Title>
                            <Card.Title>{`Total Questions: ${submission.total_questions}`}</Card.Title>
                        </Card.Body>
                    </Card> :
                    null}
                {questions}
            </Container>
        </div>
    )
}

export default ViewSubmission
