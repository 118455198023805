import React, { useEffect, useState, useContext } from 'react'
import { Card, Container, Accordion, Button, Row, Col } from 'react-bootstrap'
import NavBar from '../components/NavBar'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AppContext } from '../utils/AppProvider'
import PrimarySidemenu from '../components/PrimarySidemenu'
import SubjectHomework from './SubjectHomework'
import { Route } from 'react-router-dom'
import ScholarshipExams from './ScholarshipExams'
import Profile from './Profile'
import Writing from './Writing'
function SelectSchoolHome(props) {
    const history = useHistory();
    const user = useSelector(state => state.user)
    const { getHomework } = useContext(AppContext)
    const [subjectName, setSubjectName] = useState()
    const [selectedSubject, setSelectedSubject] = useState()
    const [weekName, setWeekName] = useState()
    const [selectedWeek, setSelectedWeek] = useState()

    useEffect(() => {
        if (user.token != "" && user.type == "primary") {
            getHomework()
        }
    }, [user.token]);
    useEffect(() => {
        if (selectedSubject) {
            setSelectedWeek()
            setWeekName()
        }
    }, [selectedSubject])
    return (
        <div>
            <PrimarySidemenu />
            <Container className="p-5">
                <div className="m-4">
                    <Route path={`${props.match.path}`} exact component={Instructions} />
                    <Route path={`${props.match.path}/subject/:subject`} component={SubjectHomework} />
                    <Route path={`${props.match.path}/scholarship`} component={ScholarshipExams} />
                    <Route path={`${props.match.path}/writing`} component={Writing} />
                    <Route path={`${props.match.path}/profile`} component={Profile} />
                </div>

            </Container>
        </div>
    )
}

export default SelectSchoolHome

function Instructions() {
    return (
        <div>
            <Card className="mt-5 m-auto shadow p-3 mb-5 bg-white rounded" >
                <Card.Body className="overflow-hidden">
                    <img src="./primaryInstructions.jpg" style={{ width: "100%" }} />
                </Card.Body>
            </Card>
        </div>
    )
}
