import React, { useState, useEffect, useContext } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { AdminContext } from '../../../utils/AdminProvider'
import ShowToast from '../../../utils/ShowToast'
import makeRequest from '../../../utils/makeRequest'
import store from '../../../utils/store'
import BtnDark from '../../../components/BtnDark'
function AddHomework(props) {
    const [active, setactive] = useState()
    const [homeworkName, setHomeworkName] = useState()
    const [subjectId, setSubjectId] = useState(0)
    const [gradeId, setGradeId] = useState(0)
    const [week, setWeek] = useState()
    const { grades, subjects, generateErrors, setHomework } = useContext(AdminContext)
    const [loading, setLoading] = useState(false)
    const addHomeworkData = () => {
        let formData = new FormData()
        formData.append('name', homeworkName)
        formData.append('grade_id', gradeId)
        formData.append('subject_id', subjectId)
        formData.append('week', week)
        if (active) {
            formData.append('active', 1)
        } else {
            formData.append('active', 0)
        }
        setLoading(true)
        makeRequest(store.getState().user.token).post('/addHomework', formData).then(res => {
            setLoading(false)
            if (res.data.status_code === 200) {
                ShowToast('success', res.data.message)
                setHomework(res.data.data)
                props.onHide()
            } else {
                ShowToast('error', res.data.message)
                generateErrors(res.data.errors)
            }
        }).catch(err => {
            setLoading(false)
            ShowToast('error', err)
        })
    }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header>
                <Modal.Title>Add Homework Questions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="add-tests-form">
                    <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="name" placeholder="Enter Test Name" onChange={(event) => { setHomeworkName(event.target.value) }} />
                    </Form.Group>
                    <Form.Group controlId="Grade_select">
                        <Form.Label>Grade</Form.Label>
                        <Form.Control as="select" onChange={(event) => { setGradeId(event.target.value) }} placeholder="Grade" >
                            <option value={0}>Select Grade</option>
                            {grades.map(item => (
                                <option key={item.id} value={item.id}>{item.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="subjects_select">
                        <Form.Label>Subject</Form.Label>
                        <Form.Control as="select" onChange={(event) => { setSubjectId(event.target.value) }} placeholder="Grade" >
                            <option value={0}>Select Subject</option>
                            {subjects.filter((value) => {
                                if (value.grade_id == gradeId) {
                                    return true
                                } else {
                                    return false
                                }
                            }).map(item => (
                                <option key={item.id} value={item.id}>{item.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="week">
                        <Form.Label>Week</Form.Label>
                        <Form.Control type="number" placeholder="Enter Week Number" onChange={(event) => { setWeek(event.target.value) }} />
                    </Form.Group>
                    <Form.Group controlId="subjects_active">
                        <Form.Check type="checkbox" label="Active" onChange={event => { setactive(event.target.checked) }} />
                    </Form.Group>
                    <BtnDark title="Submit" loading={loading} onClick={(event) => {
                        event.preventDefault()
                        if (subjectId != null && subjectId != 0 && homeworkName && homeworkName.trim() != "") {
                            addHomeworkData()
                        } else {
                            toast.error("Please enter/select all the fields", {
                                autoClose: false,
                                position: "top-right",
                                draggable: true,
                                closeOnClick: true,
                                autoClose: 5000,
                                hideProgressBar: true
                            })
                        }
                    }} />
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default AddHomework
