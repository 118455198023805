import React, { useEffect, useState } from 'react'
import ShowToast from '../../../utils/ShowToast'
import makeRequest from '../../../utils/makeRequest'
import store from '../../../utils/store'
import { Container, Card, Row, Button, ListGroup, Form } from 'react-bootstrap'
import FAB from '../../../components/FAB'
import AddSession from './AddSession'
import ViewComment from './ViewComment'
function ViewTutor(props) {
    const [loading, setLoading] = useState(false)
    const [sessions, setsessions] = useState({})
    const [tutor, setTutor] = useState()
    const [addDialog, setAddDialog] = useState(false)
    const [formatted, setFormatted] = useState([])
    const [selected, setSelected] = useState()
    const [show, setShow] = useState(false)
    const [week, setWeek] = useState()
    useEffect(() => {
        let data = new FormData()
        data.append('id', props.match.params.id)
        setLoading(true)
        makeRequest(store.getState().user.token).post('/getTutorSessions', data).then(res => {
            setLoading(false)
            if (res.data.status_code === 200) {
                setsessions(res.data.data)
                setTutor(res.data.tutor)
            } else {
                ShowToast('error', res.data.message)
                ShowToast('error', res.data.errors)
            }
        }).catch(err => {
            setLoading(false)
            ShowToast('error', 'Something went wrong')
        })

    }, [props.match.params.id])
    return (
        <Container className="mt-2">
            <div>
                <ViewComment
                    show={show}
                    onHide={() => { setShow(false) }}
                    item={selected}
                />
                <AddSession
                    show={addDialog}
                    onHide={() => { setAddDialog(false) }}
                    tutor_id={tutor?.id}
                    myfun={(data) => setsessions(data).bind(this)} />
                {sessions ?
                    <Card className="shadow rounded">
                        <Card.Body>
                            <Card.Title>{sessions.name}</Card.Title>
                            <Card.Text>Username: {tutor?.username}</Card.Text>
                            <Card.Text>Email: {tutor?.email}</Card.Text>
                        </Card.Body>
                    </Card>
                    : null}
                {
                    Object.keys(sessions).length > 0 ?
                        <Card className="shadow rounded mt-2">
                            <Card.Header>
                                <Card.Title>Sessions</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Form.Control as="select" onChange={(event) => { setWeek(event.target.value) }} value={week}>
                                <option value={null}>Please select week</option>
                                    {Object.keys(sessions).map((item, index) => {
                                        return (
                                            <option value={item} key={`week${index}`}>{`Week ${item}`}</option>
                                        )
                                    })}
                                </Form.Control>
                                <Row style={{ display: 'flex', justifyContent: "space-around" }} className="mt-2">
                                    {week ?
                                        Object.keys(sessions[week])?.map((item, index) => {
                                            if (sessions[week][item].length == 0) return
                                            return (
                                                <Card border="light" style={{ width: '18rem' }} className="m-2 shadow-sm p-1 mb-5 bg-white rounded" key={String(index)}>
                                                    <Card.Body>
                                                        <Card.Title>{item}</Card.Title>
                                                        <ListGroup>
                                                            {sessions[week][item].map((element, index) => (
                                                                element.feedback == null ?
                                                                    <ListGroup.Item
                                                                        action
                                                                        key={`item${index}`}>
                                                                        {element.name}
                                                                    </ListGroup.Item> :
                                                                    <ListGroup.Item
                                                                        variant="success"
                                                                        action
                                                                        key={`item${index}`}
                                                                        onClick={() => {
                                                                            setSelected(element)
                                                                            setShow(true)
                                                                        }}>
                                                                        {element.name}
                                                                    </ListGroup.Item>
                                                            ))}
                                                        </ListGroup>
                                                    </Card.Body>
                                                </Card >
                                            )
                                        })
                                        : null}
                                </Row>
                            </Card.Body>
                        </Card> : null}
            </div>
            <FAB onPress={() => { setAddDialog(true) }} />
        </Container>
    )
}

export default ViewTutor
