import React, { useState, useEffect } from 'react'
import { Modal, Button, Form, Spinner, Card, Container, Row, Col, ListGroup } from 'react-bootstrap'
import makeRequest from '../../../utils/makeRequest'
import store from '../../../utils/store'
import ShowToast from '../../../utils/ShowToast'
import moment from 'moment'
function ViewReport(props) {
    const [report, setReport] = useState()
    const [loading, setLoading] = useState(false)
    const [tests, setTests] = useState()
    const [sets, setSets] = useState()
    const [subjectId, setSubjectId] = useState(null)
    useEffect(() => {
        let data = new FormData()
        data.append('id', props.match.params.id)
        setLoading(true)
        makeRequest(store.getState().user.token).post('/getStudentReport', data).then(res => {
            setLoading(false)
            if (res.data.status_code === 200) {
                setReport(res.data)
            } else {
                ShowToast('error', res.data.message)
                ShowToast('error', res.data.errors)
            }
        }).catch(err => {
            setLoading(false)
            ShowToast('error', 'Something went wrong')
        })
    }, [props.match.params.id])
    useEffect(() => {
        if (report) {
            let tests = [];
            report.user.grade.subjects.forEach(item => {
                if (item.active_tests.length == 0) return
                tests.push(
                    <Card border="light" style={{ width: '18rem' }} className="m-2 shadow-sm p-1 mb-5 bg-white rounded" key={item.id}>
                        <Card.Body>
                            <Card.Title>{item.name}</Card.Title>
                            <ListGroup >
                                {item.active_tests.map(element => {
                                    let submission = report.user.submissions.find(x => x.test_id === element.id)
                                    return (
                                        !submission ?
                                            <ListGroup.Item
                                                action
                                                key={element.id}
                                            >{element.name}</ListGroup.Item> :
                                            <ListGroup.Item
                                                variant="success"
                                                action
                                                onClick={() => { window.open(`/view/submission/${element.id}/${submission.id}/test`, "_blanks") }}
                                                key={element.id}>
                                                {`${element.name} (${submission.score}/${submission.total_questions})`} </ListGroup.Item>
                                    )
                                })}
                            </ListGroup>
                        </Card.Body>
                    </Card >)
            });
            setTests(tests)
        }
    }, [report])
    const generateSets = () => {
        let s = []
        report.homeworks.forEach(item => {
            if (item.subject_id == subjectId) {
                item.homeworks.map((element, index) => {
                    if (element.week_homework.length == 0) return
                    return (
                        s.push(
                            <Card border="light" style={{ width: '18rem' }} className="m-2 shadow-sm p-1 mb-5 bg-white rounded" key={`week${element.week}`}>
                                <Card.Body>
                                    <Card.Title>{`Week ${element.week}`}</Card.Title>
                                    <ListGroup >
                                        {element.week_homework.map(set => {
                                            let submission = report.user.homework_submissions.find(x => x.homework_id === set.id)
                                            return (
                                                !submission ?
                                                    <ListGroup.Item
                                                        action
                                                        key={set.id}
                                                    >{set.name}
                                                    </ListGroup.Item> :
                                                    <ListGroup.Item
                                                        variant="success"
                                                        action
                                                        onClick={() => { window.open(`/view/submission/${set.id}/${submission.id}/homework`, "_blanks") }}
                                                        key={set.id}>
                                                        {`${set.name} (${submission.score}/${submission.total_questions})`}
                                                    </ListGroup.Item>
                                            )
                                        }
                                        )}
                                    </ListGroup>
                                </Card.Body>
                            </Card >
                        )
                    )
                })
            }

        });
        setSets(s)
    }
    useEffect(() => {
        if (report) {
            generateSets()
        }
    }, [subjectId])
    return (
        loading || !report ? <Spinner animation="border" variant="dark" style={{ position: "absolute", top: "50%", bottom: "50%", left: "50%", right: "50%" }} /> :
            <Container>
                <Card className="m-2 shadow-sm mb-5 bg-white rounded">
                    <Card.Header>
                        <Card.Title>{report.user.name}</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col>
                                <Card.Text>Username: <strong>{report.user.username}</strong></Card.Text>
                                <Card.Text>Grade: <strong>{report.user.grade.name}</strong></Card.Text>
                                <Card.Text>Email: <strong>{report.user.email}</strong></Card.Text>
                                <Card.Text>Type: <strong>{report.user.type}</strong></Card.Text>
                            </Col>
                            <Col>
                                <Card.Text>Last Homework: <strong>{report.last_homework != null ? moment(report.last_homework.created_at).format('MMMM Do YYYY, HH:mm') : " - "}</strong></Card.Text>
                                <Card.Text>Last Test: <strong>{report.last_test != null ? moment(report.last_test.created_at).format('MMMM Do YYYY, HH:mm') : " - "}</strong></Card.Text>
                                <Card.Text>Homeworks Completed: <strong>{report.user.homework_submissions.length}</strong></Card.Text>
                                <Card.Text>Tests Completed: <strong>{report.user.submissions.length}</strong></Card.Text>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                {report.user.type == "primary" &&
                    <Card className="m-2 shadow-sm mb-5 bg-white rounded">
                        <Card.Header>
                            <Card.Title>
                                Homework
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Form.Group controlId="subject_select" style={{ marginLeft: 16 }}>
                                <Form.Control as="select" onChange={(event) => { setSubjectId(event.target.value) }} placeholder="Subject" >
                                    <option value={null}>Select Subject</option>
                                    {report.homeworks.map(item => {
                                        if (item.homeworks.length == 0) return
                                        return (
                                            <option key={item.id} value={item.subject_id}>{item.subject_name}</option>
                                        )
                                    })}
                                </Form.Control>
                            </Form.Group>
                            {sets}
                        </Card.Body>
                    </Card>}
                <Card className="m-2 shadow-sm mb-5 bg-white rounded">
                    <Card.Header>
                        <Card.Title>{report.user.type == "primary" ? "Scholarship Exams" : "Tests"}</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        {tests}
                    </Card.Body>
                </Card>

            </Container>
    )
}

export default ViewReport
