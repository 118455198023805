import { Button, Form, Modal } from 'react-bootstrap'
import React from 'react'
import ShowToast from '../../../utils/ShowToast'

export default function EditBilling(props) {
    const [name,setName] = React.useState("")
    const [email,setEmail] = React.useState("")
    React.useEffect(()=>{
        if(props.item){
            setName(props.item.name)
            setEmail(props.item.email)
        }
    },[props.item])
    const update = async ()=>{
        let formData = new FormData()
        formData.append("name",name)
        formData.append("email",email)
        formData.append("id",props.item.id)
        const response = await fetch("https://regapi.pointcooktutoring.com/api/updateBillingInfo",{method:"POST",body:formData})
        const responseJson = await response.json()
        if(responseJson.status_code === 200){
            let d =[]
            props.data.forEach(element => {
                if(element.id === props.item.id){
                    element.name = name
                    element.email = email
                    d.push(element)
                }else{
                    d.push(element)
                }
            });
            props.updateData(d)
            ShowToast("success",responseJson.message)
            props.onHide()
        }else{
            ShowToast('error',responseJson.message)
        }
    }
    return (
        <Modal
            size="xl"
            {...props}
            centered>
            <Modal.Header closeButton>Edit Info</Modal.Header>
            <Modal.Body>
                <Form.Group controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="name" placeholder="Name" onChange={(event) => { setName(event.target.value) }} value={name} />
                </Form.Group>
                <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Email" onChange={(event) => { setEmail(event.target.value) }} value={email} />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={update}>Save</Button>
            </Modal.Footer>
        </Modal>
    )
}
