import React, { useContext, useState, useEffect } from 'react'
import { Form, Col, Table, Container, Spinner } from 'react-bootstrap'
import { AdminContext } from '../../utils/AdminProvider'
import BtnDark from '../../components/BtnDark'
import EditStudent from './Students/EditStudent'
import ViewReport from './Students/ViewReport'
import NextPrev from '../../components/NextPrev'
import FAB from '../../components/FAB'
import AddStudent from './Students/AddStudent'
import Switch from 'react-switch'
function Students() {
    const { grades, loading, getStudents, students, updateStudentStatus } = useContext(AdminContext)
    const [gradeId, setGradeId] = useState(null)
    const [type, setType] = useState(null)
    const [name, setName] = useState("")
    const [show, setShow] = useState(false)
    const [selected, setSelected] = useState()
    const [showTestModal, setShowTestModal] = useState(false)
    const [addStudentDialog, setAddStudentDialog] = useState(false)
    const [page, setPage] = useState(0)
    useEffect(() => {
        getStudents(gradeId, type, name, page)
    }, [page])
    return (
        <Container>
            <div className="d-flex flex-column justify-content-center mt-3">
                <EditStudent
                    show={show}
                    onHide={() => { setShow(false) }}
                    item={selected}
                    gradeId={gradeId}
                    type={type}
                    name={name} />
                <AddStudent
                    show={addStudentDialog}
                    onHide={() => { setAddStudentDialog(false) }}
                />
                <Form id="view-tests-form" style={{ alignSelf: "center" }}>
                    <Form.Row>
                        <Form.Group controlId="name" as={Col}>
                            <Form.Control type="name" placeholder="Enter Name" onChange={(event) => { setName(event.target.value) }} />
                        </Form.Group>
                        <Form.Group controlId="grade_select" as={Col}>
                            <Form.Control as="select" onChange={(event) => { setGradeId(event.target.value) }} placeholder="Grade" >
                                <option value={null}>Select Grade</option>
                                {grades.map(item => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="type_select" as={Col}>
                            <Form.Control as="select" onChange={(event) => { setType(event.target.value) }} placeholder="Type" >
                                <option value={null}>Select Type</option>
                                <option value="primary">Primary</option>
                                <option value="selectschool">Select School</option>
                            </Form.Control>
                        </Form.Group>
                        <BtnDark
                            title="Submit"
                            onClick={(event) => {
                                event.preventDefault()
                                getStudents(gradeId, type, name, 0)
                            }} />
                        <div style={{ marginLeft: 8 }}>
                            <BtnDark
                                title="Clear"
                                onClick={(event) => {
                                    event.preventDefault()
                                    setPage(0)
                                    getStudents(null, null, "", 0)
                                }} />
                        </div>
                    </Form.Row>
                </Form>
                <div className="d-flex justify-content-center">
                    {loading && <Spinner animation="border" variant="dark" className="m-5" />}
                </div>
                {grades.length > 0 && students && !loading &&
                    <Table striped bordered hover variant="dark">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Username</th>
                                <th>Email</th>
                                <th>Grade</th>
                                <th>Type</th>
                                <th>Active</th>
                                <th className="text-center">options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {students.map((item, index) => (
                                <tr key={String(index)}>
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>
                                    <td>{item.username}</td>
                                    <td>{item.email}</td>
                                    <td>{grades.find(x => x.id === item.grade_id).name}</td>
                                    <td>{item.type}</td>
                                    <td>
                                        <Switch checked={item.active == 1 ? true : false} onChange={(checked) => { updateStudentStatus(item.id, checked) }} />
                                    </td>
                                    <td>
                                        <i
                                            className="fa fa-edit"
                                            onClick={() => {
                                                setSelected(item)
                                                setShow(true)
                                            }} />
                                        <a href={`/view/student/${item.id}`} target="_blank">
                                            <i className="fa fa-eye ml-3" style={{ color: "#fff" }} />
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>}
                <div className="d-flex justify-content-center">
                    <NextPrev setPage={(page) => { setPage(page) }} page={page} monitor={students} />
                </div>
            </div>
            <FAB onPress={() => { setAddStudentDialog(true) }} />
        </Container>
    )
}

export default Students
