import React, { useState, useEffect, useContext } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import '../../../css/tabs.css'
import { AdminContext } from '../../../utils/AdminProvider'
import ShowToast from '../../../utils/ShowToast'
import makeRequest from '../../../utils/makeRequest'
import store from '../../../utils/store'
import BtnDark from '../../../components/BtnDark'
function EditTest(props) {
    const [active, setActive] = useState()
    const [testName, setTestName] = useState()
    const [subjectId, setSubjectId] = useState(null)
    const [gradeId, setGradeId] = useState(null)
    const { grades, subjects, generateErrors, setTests } = useContext(AdminContext)
    const [loading, setLoading] = useState(false)
    const [id, setId] = useState()
    const editTestData = () => {
        let formData = new FormData()
        formData.append('id', id)
        formData.append('name', testName)
        formData.append('subject_id', subjectId)
        formData.append('grade_id', gradeId)
        if (active) {
            formData.append('active', 1)
        } else {
            formData.append('active', 0)
        }
        setLoading(true)
        makeRequest(store.getState().user.token).post('/editTest', formData).then(res => {
            setLoading(false)
            if (res.data.status_code === 200) {
                ShowToast('success', res.data.message)
                setTests(res.data.data)
                props.onHide()
            } else {
                ShowToast('error', res.data.message)
                generateErrors(res.data.errors)
            }
        }).catch(err => {
            setLoading(false)
            ShowToast('error', err)
        })
    }
    useEffect(() => {
        if (props.item) {
            setId(props.item.id)
            setTestName(props.item.name)
            setGradeId(props.item.grade_id)
            setSubjectId(props.item.subject_id)
            setActive(props.item.active == 1 ? true : false)
        }
    }, [props.item])
    return (
        <Modal
            {...props}
            size="m"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add Test
      </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="add-tests-form" >
                    <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="name" placeholder="Enter Test Name" onChange={(event) => { setTestName(event.target.value) }} value={testName} />
                    </Form.Group>
                    <Form.Group controlId="grade_select" >
                        <Form.Control as="select" onChange={(event) => {
                            setGradeId(event.target.value)
                            setSubjectId(null)
                        }} placeholder="Grade" value={gradeId} >
                            <option value={null}>Select Grade</option>
                            {grades.map(item => (
                                <option key={item.id} value={item.id}>{item.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="subject_select" >
                        <Form.Control as="select" onChange={(event) => { setSubjectId(event.target.value) }} placeholder="Subject" value={subjectId}>
                            <option value={null}>Select Subject</option>
                            {subjects.filter((value) => {
                                if (value.grade_id == gradeId) {
                                    return true
                                } else {
                                    return false
                                }
                            }).map(item => (
                                <option key={item.id} value={item.id}>{item.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="subjects_active">
                        <Form.Check type="checkbox" label="Active" onChange={event => { setActive(event.target.checked) }} checked={active} />
                    </Form.Group>
                    <BtnDark title="Submit" loading={loading} onClick={(event) => {
                        event.preventDefault()
                        if (subjectId != null && subjectId != 0 && testName != null && gradeId != null) {
                            editTestData()
                        } else {
                            toast.error("Please enter/select all the fields", {
                                autoClose: false,
                                position: "top-right",
                                draggable: true,
                                closeOnClick: true,
                                autoClose: 5000,
                                hideProgressBar: true
                            })
                        }
                    }} />
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default EditTest
