import { toast } from "react-toastify"
export default function ShowToast(type, message) {
    let options = {
        autoClose: 5000,
        position: "top-right",
        progress: undefined,
        draggable: true,
        closeOnClick: true
    }
    if (type == "error") {
        toast.error(message, options)
    } else if (type = "success") {
        toast.success(message, options)
    } else if (type == "info") {
        toast.info(message, options)
    }
}