import React from 'react'
import '../css/home.css'
import Button from 'react-bootstrap/Button'
function HomeScreen() {
    return (
        <div className="container-fluid">
            <div className="d-flex align-items-center min-vh-100 flex-column justify-content-center">
                <p className="title">Point Cook Tutoring</p>
                <p>(Test)</p>
                <Button variant="outline-secondary" href="/login">Get Started</Button>
            </div>
        </div>
    )
}

export default HomeScreen
