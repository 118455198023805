import * as t from '../actions/types'
const initialState = {
    loading: true,
    data: [],
    error: false,
    message: ""
}
export default function testReducer(state = initialState, action) {
    switch (action.type) {
        case t.GET_TEST_DATA:
            return {
                ...state,
                loading: true
            }
        case t.GET_TEST_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.data,
                error: false,
                message: action.payload.message
            }
        case t.GET_TEST_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                error: true,
                message: action.payload.message
            }
        case t.SEND_TEST_DATA:
            return {
                ...state,
                loading: true,
                error:false,
                message:""
            }
        case t.SEND_TEST_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                message: action.payload.message
            }
        case t.SEND_TEST_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                message: action.payload.message
            }
        default:
            return state
    }
}