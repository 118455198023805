import React, { useState } from 'react'
import makeRequest from './makeRequest'
import store from './store'
import ShowToast from '../utils/ShowToast'
export const AppContext = React.createContext()

export const AppProvider = props => {
    const [loading, setLoading] = useState(false)
    const [homework, setHomework] = useState([])
    const getHomework = () => {
        setLoading(true)
        makeRequest(store.getState().user.token).get('getHomework').then(res => {
            setLoading(false)
            if (res.data.status_code === 200) {
                setHomework(res.data.data)
            } else {
                ShowToast(res.data.message)
            }
        }).catch(err => {
            ShowToast('error', "Something went wrong!")
            setLoading(false)
        })
    }
    const generateErrors = (data) => {
        for (var key in data) {
            data[key].map((item, index) => {
                ShowToast('error', item)
            })
        }
    }
    return (
        <AppContext.Provider value={{ loading, getHomework, homework, generateErrors }}>
            {props.children}
        </AppContext.Provider>
    )
}

