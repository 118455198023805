import * as t from '../actions/types'
const initialState = {
    loading: false,
    data: [],
    error: false,
    message: ""
}

export default function homeReducer(state = initialState, action) {
    switch (action.type) {
        case t.GET_MASTER_DATA:
            return {
                ...state,
                loading: true
            }
        case t.GET_MASTER_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                error: false,
                message: action.payload.message,
                loading: false
            }
        case t.GET_MASTER_DATA_FAILURE:
            return {
                ...state,
                data: [],
                error: true,
                message: action.payload.message,
                loading: false
            }
        default:
            return state
    }
}