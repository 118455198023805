import React, { memo, useState, useEffect } from 'react';
import { Card, Form, Spinner, Row, Col, Carousel } from 'react-bootstrap';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import '../css/charts.css'
import '../css/gradient.css'
import makeRequest from '../utils/makeRequest';
import store from '../utils/store';
function Charts() {
  const [selected, setSelected] = useState(0)
  const [chartData, setChartData] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    getChartData()
  }, [])
  const getChartData = () => {
    setLoading(true)
    makeRequest(store.getState().user.token).get('/chartData').then(res => {
      setLoading(false)
      if (res.data.status_code === 200) {
        setChartData(res.data.data)
      }
    }).catch(err => {
      setLoading(false)
    })
  }
  return (
    chartData.length === 0 ? <div></div> :
      (chartData.length === 1 && chartData[0].testData.length === 0) ? <div></div> :
        <Card className="mt-5 m-auto shadow p-3 mb-5 bg-white rounded" >
          <Card.Title>Report</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Control as="select" onChange={(event) => { setSelected(event.target.value) }} value={selected}>
                {chartData.map((item, index) => {
                  return (
                    <option value={index} key={String(index)}>{item.subject}</option>
                  )
                })}
              </Form.Control>
            </Form.Group>
          </Card.Subtitle>
          <Card.Body>
            {loading && <Spinner animation="border" variant="dark" />}
            {chartData.length > 0 &&
              <Row className="d-flex justify-content-center ">
                {chartData[selected].testData.map((item, index) => (
                  <Card className={`border-0 shadow bg-dark m-2 cardSize ${item.test_name}`} key={`circular${index}`}>
                    <Card.Body className="d-flex flex-column align-items-center">
                      <Card.Title style={{ color: "#fff" }}>{item.test_name}</Card.Title>
                      <CircularProgressbar
                        className="circularSize"
                        value={item.score}
                        maxValue={item.max}
                        text={`${item.score}/${item.max}`}
                        styles={buildStyles({
                          rotation: 0.25,
                          strokeLinecap: 'round',
                          textSize: '16px',
                          pathTransitionDuration: 0.5,
                          pathColor: `rgba(255, 255, 255, 1)`,
                          textColor: '#fff',
                          trailColor: 'rgba(255, 255, 255, 0.5)',
                          backgroundColor: '#000',
                        })}
                      />
                    </Card.Body>
                  </Card>

                ))}
              </Row>
            }
          </Card.Body>
        </Card>
  )
}

export default memo(Charts)
