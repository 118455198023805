import React, { useContext, useState, useEffect } from 'react'
import { AdminContext } from '../../utils/AdminProvider'
import { Col, Form, Table, Spinner, Container } from 'react-bootstrap'
import BtnDark from '../../components/BtnDark'
import EditHomework from './Homework/EditHomework'
import AddHomeworkQuestions from './Homework/AddHomeworkQuestions'
import FAB from '../../components/FAB'
import AddHomework from './Homework/AddHomework'
import makeRequest from '../../utils/makeRequest'
import store from '../../utils/store'
import ShowToast from '../../utils/ShowToast'

function Homework() {
    const { grades, subjects, homework, loading } = useContext(AdminContext)
    const [gradeId, setGradeId] = useState(0)
    const [subjectId, setSubjectId] = useState(0)
    const [week, setWeek] = useState(0)
    const [selected, setSelected] = useState()
    const [show, setShow] = useState(false)
    const [filtered, setFiltered] = useState([])
    const [localLoading, setLocalLoading] = useState(false)
    const [questionDialog, setQuestionDialog] = useState(false)
    const [addHomeworkDialog, setAddHomeworkDialog] = useState(false)
    const [term, setTerm] = useState(0)
    const download = async (id)=>{
        let data = new FormData()
        data.append("id",id)
        data.append("homework","1")
        const response = await makeRequest(store.getState().user.token).post('/downloadExcel',data)
        if(response.data.status_code == 200){
            window.location.href = response.data.data
        }else{
            ShowToast("error",response.data.message)
        }
    }
    useEffect(() => {
        if (homework && homework.length > 0 && filtered.length == 0) {
            setFiltered(homework)
        } else if (homework && homework.length > 0) {
            setFiltered(homework)
            filter()
        }
    }, [homework])
    const filter = () => {
        setLocalLoading(true)
        let ff = homework.filter((value) => {
            if (gradeId != 0) {
                if (subjectId != 0) {
                    if (week != 0) {
                        if (value.grade_id == gradeId && value.subject_id == subjectId && value.week == week) {
                            return true
                        }
                    } else {
                        if (value.grade_id == gradeId && value.subject_id == subjectId) {
                            return true
                        }
                    }
                } else {
                    if (week != 0) {
                        if (value.grade_id == gradeId && value.week == week) {
                            return true
                        }
                    } else {
                        if (value.grade_id == gradeId) {
                            return true
                        }
                    }
                }
            } else if (week != 0) {
                if (value.week == week) {
                    return true
                }
            } else {
                return true
            }
        })
        if (term != 0) {
            let termed = ff.filter(x => {
                if (x.term_id == term) {
                    return true
                } else {
                    return false
                }
            })
            setFiltered(termed)
        } else {
            setFiltered(ff)
        }
        setLocalLoading(false)
    }
    return (
        <Container>
            <EditHomework
                show={show}
                onHide={() => { setShow(false) }}
                item={selected} />
            <AddHomeworkQuestions
                show={questionDialog}
                onHide={() => { setQuestionDialog(false) }}
                item={selected}
            />
            <AddHomework
                show={addHomeworkDialog}
                onHide={() => { setAddHomeworkDialog(false) }}
            />
            <div className="d-flex flex-column mt-3 align-items-center">
                <Form id="view-tests-form" style={{ alignSelf: "center" }}>
                    <Form.Row>
                        <Form.Group controlId="grade_select" as={Col}>
                            <Form.Control as="select" onChange={(event) => { setGradeId(event.target.value) }} placeholder="Grade" >
                                <option value={0}>Select Grade</option>
                                {grades.map(item => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="subject_select" as={Col}>
                            <Form.Control as="select" onChange={(event) => { setSubjectId(event.target.value) }} placeholder="Subject" >
                                <option value={0}>Select Subject</option>
                                {subjects.filter((value) => {
                                    if (value.grade_id == gradeId) {
                                        return true
                                    } else {
                                        return false
                                    }
                                }).map(item => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="term_select" as={Col}>
                            <Form.Control as="select" onChange={(event) => { setTerm(event.target.value) }} placeholder="Term" >
                                <option value={0}>Select Term</option>
                                <option value={1}>Term 1</option>
                                <option value={2}>Term 2</option>
                                <option value={3}>Term 3</option>
                                <option value={4}>Term 4</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="week" as={Col}>
                            <Form.Control type="number" placeholder="Enter Week Number" onChange={(event) => { setWeek(event.target.value) }} value={week} />
                        </Form.Group>
                        <BtnDark
                            title="Search"
                            onClick={(event) => {
                                event.preventDefault()
                                filter()
                            }} />
                        <div style={{ marginLeft: 8 }}>
                            <BtnDark
                                title="Clear"
                                onClick={(event) => {
                                    event.preventDefault()
                                    setFiltered(homework)
                                }}
                            /></div>

                    </Form.Row>
                </Form>
                {(loading || localLoading) ? <Spinner animation="border" variant="dark" className="align-self-center" /> :
                    <Table striped bordered hover variant="dark">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Grade</th>
                                <th>Subject</th>
                                <th>Term</th>
                                <th>Week</th>
                                <th>Active</th>
                                <th className="text-center">options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filtered.map((item, index) => {
                                let gradeName = grades.find(x => x.id === item.grade_id).name
                                let subjectName = subjects.find(x => x.id === item.subject_id).name
                                return (
                                    <tr key={String(index)}>
                                        <td>{item.id}</td>
                                        <td>{item.name}</td>
                                        <td>{gradeName}</td>
                                        <td>{subjectName}</td>
                                        <td>{`Term ${item.term_id}`}</td>
                                        <td>{item.week}</td>
                                        <td>{item.active}</td>
                                        <td >
                                            <i
                                                className="fa fa-edit"
                                                onClick={() => {
                                                    setSelected(item)
                                                    setShow(true)
                                                }} />
                                            <i
                                                className="fa fa-plus ml-3"
                                                onClick={() => {
                                                    setSelected(item)
                                                    setQuestionDialog(true)
                                                }} />
                                            <a href={`/view/homework/${gradeName}/${subjectName}/${item.week}/${item.id}`} target="_blank">
                                                <i className="fa fa-eye ml-3" style={{ color: "#fff" }} />
                                            </a>
                                            <a>
                                                <i className="fa fa-download ml-3" style={{ color: "#fff" }} onClick={()=>download(item.id)}/>
                                            </a>
                                        </td>
                                    </tr>
                                )
                            }
                            )}
                        </tbody>
                    </Table>}
            </div>
            <FAB onPress={() => { setAddHomeworkDialog(true) }} />
        </Container>
    )
}

export default Homework
