import React from 'react'
import Spinner from 'react-bootstrap/Spinner'
function Loader() {
    return (
        <div style={{ position: "absolute", width: "100%", height: "100%", zIndex: 999, backgroundColor: "rgba(0,0,0,0.7)" }}>
            <div style={{
                position: 'absolute', top: "50%", left: "50%"
            }}>
                <Spinner animation="grow" variant="light" />
            </div>
        </div>
    )
}

export default Loader
