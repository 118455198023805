import React from 'react'
import { useDispatch } from 'react-redux'
import { logout } from '../actions/loginActions'
import { useHistory } from 'react-router-dom';
import makeRequest from '../utils/makeRequest';
import store from '../utils/store';

function Logout() {
    const dispatch = useDispatch();
    const history = useHistory()
    React.useEffect(() => {
        makeRequest(store.getState().user.token).get('/processLogout').then(res => {            
            if (res.data.status_code == 200) {
                dispatch(logout())
                history.push('/')
            }
        }).catch(err => {
            dispatch(logout())
            history.push('/')
        })
    },[])
    return (
        <div>

        </div>
    )
}

export default Logout
