import React, { useEffect, useState } from 'react'
import { Row, Card, ListGroup, Container, Col, Spinner } from 'react-bootstrap'
import NavBar from '../components/NavBar'
import { useDispatch, useSelector } from 'react-redux'
import { getMaster } from '../actions/homeActions'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
function Writing(props) {
    const [tests, setTests] = useState([])
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector(state => state.user)
    const home = useSelector(state => state.home)
    const data = home.data
    useEffect(() => {
        if (props.location.state) {
            toast.success(props.location.state.message, {
                autoClose: false,
                position: "top-right",
                progress: undefined,
                draggable: true,
                closeOnClick: true
            })
            history.replace('/home/writing')
        }

    }, [props.location.state])
    useEffect(() => {
        if (user.token != "") {
            dispatch(getMaster())
        }
    }, [user.token]);
    useEffect(() => {
        let tests = [];
        data.forEach(item => {
            if (item.active_tests.length == 0) return
            tests.push(
                <Card border="light" style={{ width: '18rem' }} className="m-2 shadow-sm p-1 mb-5 bg-white rounded" key={item.id} as={Col}>
                    <Card.Body>
                    <Card.Title>{item.name}</Card.Title>
                        <ListGroup >
                            {item.active_tests.map(element => (
                                element.user_submission == null ?
                                    <ListGroup.Item
                                        action
                                        key={element.id}
                                        onClick={() => {
                                            history.push('/test', { fromHome: true, test_id: element.id, backTo: "/home/writing" })
                                        }}>{element.name}</ListGroup.Item> :
                                    <ListGroup.Item
                                        variant="success"
                                        action
                                        key={element.id}
                                        onClick={() => {
                                            history.push('/test', { fromHome: true, test_id: element.id, submission: element.user_submission, backTo: "/home/writing" })
                                        }}>
                                        <div style={{display:"flex",justifyContent:"space-between"}}>
                                        {element.name}
                                        {JSON.parse(element.user_submission.test_data).checked ?
                                            <img src={require('../assets/bell.png')}/>
                                            :
                                            null
                                        }</div>
                                    </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Card.Body>
                </Card >)
        });
        setTests(tests)
    }, [data])
    return (
        <div>
            <Container className="p-5">
                <Card className="mt-5 m-auto shadow p-3 mb-5 bg-white rounded" >
                    <Card.Title className="mb-2">Writing</Card.Title>
                    <Card.Body>
                        <Row style={{ display: 'flex' }}>
                            {home.loading && <Spinner animation="border" variant="dark" className="align-self-center" />}
                            {tests}
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    )
}

export default Writing
