import * as t from './types'
import store from '../utils/store'
import makeRequest from '../utils/makeRequest'

export const getMasterData = ({
    type: t.GET_MASTER_DATA
})

export const getMasterDataSuccess = data => ({
    type: t.GET_MASTER_DATA_SUCCESS,
    payload: data
})

export const getMasterDataFailure = data => ({
    type: t.GET_GRADE_DATA_FAILURE,
    payload: data
})

export const getMaster = () => {
    return (dispatch) => {
        dispatch(getMasterData)
        return makeRequest(store.getState().user.token).get('/getAvailableTests').then(res => {
            if(res.data.status_code === 200){
                dispatch(getMasterDataSuccess(res.data))
            }else{
                dispatch(getMasterDataFailure(res.data))
            }
        }).catch(err=>{
            dispatch(getMasterDataFailure({message:"Something Went Wrong"}))
        })
    }
}