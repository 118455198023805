import React, { useState, useEffect, useContext } from 'react'
import { Form, Spinner, Card, Container } from 'react-bootstrap'
import { toast } from 'react-toastify'
import store from '../../../utils/store'
import makeRequest from '../../../utils/makeRequest'
import '../../../css/test.css'
import { MathfieldComponent } from 'react-mathlive'

function ViewTest(props) {
    // const [testId, setTestId] = useState()
    const [loading, setLoading] = useState(false)
    const [testData, setTestData] = useState()
    const [questions, setQuestions] = useState()
    const getTest = (id) => {
        // setTestId(id)
        var data = new FormData()
        data.append('test_id', id);
        setLoading(true)
        makeRequest(store.getState().user.token).post('/getTestQuestions', data).then(res => {
            if (res.data.status_code === 200) {
                setTestData(res.data.data[0]);
                setLoading(false)
            } else {
                setLoading(false)

            }
        }).catch(err => {
            toast.error("Something went Wrong!", {
                autoClose: true,
                position: "top-right",
                draggable: true,
                closeOnClick: true,
                autoClose: 5000,
                hideProgressBar: true
            })
            setLoading(false)

        })
    }
    useEffect(() => {
        getTest(props.match.params.testid)
    }, [props.match.params.testid])

    let alphabets = ['A. ', 'B. ', 'C. ', 'D. ', 'E. ', 'F. ', 'G. ', 'H. ', 'I. ', 'J. ', 'K. ', 'L. ', 'M. ', 'N. ', 'O. ', 'P. ', 'Q. ', 'R. ', 'S. ', 'T. ', 'U. ', 'V. ', 'W. ', 'X. ', 'Y. ', 'Z .']
    useEffect(() => {
        if (testData) {
            let q = []
            let i = 1
            testData.questions.forEach(element => {
                q.push(
                    <div>
                        {element.intro_text != null && <div className="m-3 " dangerouslySetInnerHTML={{ __html: element.intro_text }}></div>}
                        {element.intro_image != null && <img className="m-3 " src={process.env.PUBLIC_URL + element.intro_image} />}
                        <Card id={element.id} className="m-3 shadow-sm" key={element.id}>
                            <Card.Header as="h5">{`Q${i}`}</Card.Header>
                            <Card.Body>
                                {element.question != null && <div className="question" dangerouslySetInnerHTML={{ __html: element.question }}></div>}
                                {element.question_image != null && <img src={process.env.PUBLIC_URL + element.question_image} />}
                                <br />
                                {element.mcq == 1 ?
                                    JSON.parse(element.options).map((option, index) => (
                                        option.type == "string" ?
                                            <div id={"cradio" + element.id + "-" + (index + 1)} key={`${element.id}${index}`}>
                                                <Form.Check
                                                    key={index}
                                                    type='radio'
                                                    id={index + 1}
                                                    name={element.id}
                                                    value={index + 1}
                                                    label={alphabets.includes(option.value + ". ") ? option.value : alphabets[index] + option.value}
                                                />
                                            </div>
                                            :
                                            <div id={"cradio" + element.id + "-" + (index + 1)} key={`${element.id}${index}`}>
                                                <Form.Check
                                                    key={index}
                                                    type='radio'
                                                    id={index + 1}
                                                    name={element.id}
                                                    value={index + 1}
                                                    label={<div style={{ flexDirection: "row" }}>
                                                        <div style={{ display: "inline" }}>{alphabets[index]}</div>
                                                        <img src={process.env.PUBLIC_URL + option.value} />
                                                    </div>}
                                                />
                                            </div>
                                    )) :
                                    <div style={{ border: "1px solid black" }} key={element.id}>
                                        <input name={element.id} id={`input${element.id}`} style={{ display: "none" }} />
                                        <MathfieldComponent
                                            // latex={latex}
                                            // onChange={(value) => fillInput(element.id, value)}
                                            mathfieldConfig={{
                                                virtualKeyboardMode: "onfocus",
                                                virtualKeyboardTheme: "material",
                                                editingOptions: {
                                                    ignoreSpacebarInMathMode: true,
                                                }
                                            }}
                                        />
                                    </div>
                                }
                            </Card.Body>
                        </Card>
                    </div>
                )
                i++
            });
            setQuestions(q)
        }
    }, [testData])
    const getImage = (type) => {
        switch (type) {
            case "Astrophile":
                return require('../../../assets/testImages/Astrophile.jpg')
            case "Balter":
                return require('../../../assets/testImages/balter.png')
            case "Bel-esprit":
                return require('../../../assets/testImages/bel-esprit.jpg')
            case "Caim":
                return require('../../../assets/testImages/caim.png')
            case "Clinquant":
                return require('../../../assets/testImages/Clinquant.png')
            case "Insouciant":
                return require('../../../assets/testImages/insouciant.jpg')
            case "Komorebi":
                return require('../../../assets/testImages/komorebi.jpg')
            case "Metanoia":
                return require('../../../assets/testImages/metanoia.png')
            case "Moonglade":
                return require('../../../assets/testImages/moon glade.jpg')
            case "Novaturient":
                return require('../../../assets/testImages/novaturient.png')
            case "Orenda":
                return require('../../../assets/testImages/orenda.png')
            case "Saorsa":
                return require('../../../assets/testImages/Saorsa.jpg')
            case "Yugen":
                return require('../../../assets/testImages/yugen.JPG')
            default:
                return null
        }
    }
    return (
        <Container>
            {questions && <h3 className="text-center">Test:{testData.name}</h3>}
            <h5 className="text-center">Grade:{props.match.params.grade}</h5>
            <h5 className="text-center">Subject:{props.match.params.subject}</h5>
            {questions && getImage(testData.name) != null &&
                <div className="text-center">
                    <img src={getImage(testData.name)} />
                </div>
            }
            {loading && <Spinner animation="border" style={{ position: "fixed", left: "50%", right: "50%", top: "50%", bottom: "50%" }} />}
            {questions}


            {testData?.questions[0].mcq == 0 ?
                <div style={{ paddingBottom: "30%" }}></div> : null}
        </Container>
    )
}

export default ViewTest
