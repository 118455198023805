import * as t from './types'
import storage from '../utils/storage'
import makeRequest from '../utils/makeRequest';
import store from '../utils/store';

export const loginRequest = ({
    type: t.LOGIN_REQUEST
})

export const loginRequestSuccess = data => ({
    type: t.LOGIN_REQUEST_SUCCESS,
    payload: data
})

export const loginRequestFailure = data => ({
    type: t.LOGIN_REQUEST_FAILURE,
    payload: data
})
export const logoutRequest = ({
    type: t.LOG_OUT
})
export const login = (username, password, history) => {
    if (!username) {
        return (dispatch) => {
            dispatch(loginRequestFailure({ message: "Please enter the username" }))
        }
    }
    if (!password) {
        return (dispatch) => {
            dispatch(loginRequestFailure({ message: "Please enter the password" }))
        }
    }
    let host = window.location.host;
    let parts = host.split(".");
    let formdata = new FormData();
    formdata.append("username", username)
    formdata.append("password", password)
    formdata.append("type", parts[0])
    return (dispatch) => {
        dispatch(loginRequest)
        return makeRequest(store.getState().user.token).post("/processLogin", formdata)
            .then(res => {
                if (res.data.status_code === 200) {
                    dispatch(loginRequestSuccess(res.data))
                    if (res.data.live === 1) {
                        history.push('/admin')
                    } else {
                        if (res.data.type.toLowerCase() === "primary") {
                            history.push('/home')
                        } else if (res.data.type.toLowerCase() === "selectschool") {
                            history.push('/home')
                        } else if (res.data.type.toLowerCase() === "test") {
                            history.push('/home')
                        } else {
                            dispatch(loginRequestFailure({ message: "Error in Login!" }))
                        }
                    }
                } else {
                    dispatch(loginRequestFailure(res.data))
                }
            })
            .catch(err => {
                dispatch(loginRequestFailure({ message: "something went wrong!" }))
                throw (err);
            })

    }
}
export const logout = () => {
    return async (dispatch) => {
        dispatch(logoutRequest)
        storage.removeItem('state')
    }
}