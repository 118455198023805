import * as t from './userTypes'
import makeRequest from '../utils/makeRequest'
import store from '../utils/store'
import ShowToast from '../utils/ShowToast'
export const uploadImage = ({
    type: t.UPLOAD_IMAGE
})
export const uploadImageSuccess = data => ({
    type: t.UPLOAD_IMAGE_SUCCESS,
    payload: data
})
export const uploadImageFail = ({
    type: t.UPLOAD_IMAGE_FAIL,
})
export const upload = (file) => {
    let data = new FormData()
    data.append("image", file)
    return (dispatch) => {
        dispatch(uploadImage)
        return makeRequest(store.getState().user.token).post("/updateProfileImage", data).then(res => {
            if (res.data.status_code == 200) {
                dispatch(uploadImageSuccess(res.data))
                ShowToast("success", res.data.message)
            } else {
                dispatch(uploadImageFail)
                ShowToast("error", res.data.message)
            }
        }).catch(err => {
            dispatch(uploadImageFail)
            ShowToast("error", "Something went wrong!")
        })
    }
}