import React, { useState, useContext, useEffect } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { AdminContext } from '../../../utils/AdminProvider'
import makeRequest from '../../../utils/makeRequest'
import store from '../../../utils/store'
import ShowToast from '../../../utils/ShowToast'
import BtnDark from '../../../components/BtnDark'
function AddQuestions(props) {
    const { grades, tests, subjects, generateErrors } = useContext(AdminContext)
    const [file, setFile] = useState()
    const [testId, setTestId] = useState(null)
    const [loading, setLoading] = useState(false)
    const addQuestionData = () => {
        let formData = new FormData()
        formData.append('test_id', testId)
        formData.append('file', file)
        setLoading(true)
        makeRequest(store.getState().user.token, 'multipart/form-data').post('/addQuestions', formData).then(res => {
            setLoading(false)
            if (res.data.status_code === 200) {
                ShowToast('success', res.data.message)
                props.onHide()
            } else {
                ShowToast('error', res.data.message)
                generateErrors(res.data.errors)
            }
        }).catch(err => {
            setLoading(false)
            ShowToast('error', "Something Went Wrong!")
        })
    }
    useEffect(() => {
        if (props.item) {
            setTestId(props.item.id)
        }
    }, [props.item])
    return (
        <Modal
            {...props}
            size="m"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add Questions
  </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="add-question-form">
                    <Form.Group>
                        <Form.File id="file" label="Select Excel file" onChange={(event) => { setFile(event.target.files[0]) }} />
                    </Form.Group>
                    <BtnDark title="Submit" loading={loading} onClick={(event) => {
                        event.preventDefault()
                        if (testId != null && testId != 0 && file != null) {
                            addQuestionData()
                        } else {
                            toast.error("Please enter/select all the fields", {
                                autoClose: false,
                                position: "top-right",
                                draggable: true,
                                closeOnClick: true,
                                autoClose: 5000,
                                hideProgressBar: true
                            })
                        }
                    }} />
                </Form>
                <a href={`${process.env.PUBLIC_URL}/Test.xlsx`} className="m-3">Download sample excel file</a>
            </Modal.Body>
        </Modal>
    )
}

export default AddQuestions


