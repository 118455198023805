import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import WelcomeScreen from './screens/WelcomeScreen';
import LoginScreen from './screens/LoginScreen';
import { useSelector } from 'react-redux'
import HomeScreen from './screens/HomeScreen';
import Logout from './screens/Logout';
import Test from './screens/Test';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Admin from './screens/Admin';
import NotFound from './screens/NotFound';
import { AppProvider } from './utils/AppProvider'
import PrimaryWelcome from './screens/PrimaryWelcome';
import SelectSchoolWelcome from './screens/SelectSchoolWelcome';
import PrimaryHome from './screens/PrimaryHome';
import SelectSchoolHome from './screens/SelectSchoolHome';
import Homework from './screens/Homework';
import ViewTest from './screens/admin/Tests/ViewTest';
import ViewHomework from './screens/admin/Homework/ViewHomework';
import ViewReport from './screens/admin/Students/ViewReport';
import ViewTutor from './screens/admin/Tutors/ViewTutor';
import ViewSubmission from './screens/admin/Students/ViewSubmission';
import Forgot from './screens/Forgot';
import ChangePassword from './screens/ChangePassword';
toast.configure();
function App() {
  const user = useSelector(state => state.user);
  const AuthorizedRoute = ({ component: Component, ...routeProps }) =>
    (
      <Route {...routeProps} render={(props) => user.isLoggedIn ? (<Component {...props} />) : (<Redirect to="/login" />)} />
    );
  let host = window.location.host;
  let parts = host.split(".");
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={parts[0] == "primary" ? PrimaryWelcome : parts[0] == "selectschool" ? SelectSchoolWelcome : WelcomeScreen} />
        <Route path="/login" component={LoginScreen} />
        <Route path="/changepassword" component={ChangePassword}/>
        <Route path="/logout" component={Logout} />
        <AuthorizedRoute path="/admin" component={Admin} />
        <AuthorizedRoute path="/view/test/:grade/:subject/:testid" component={ViewTest} />
        <AuthorizedRoute path="/view/homework/:grade/:subject/:week/:homeworkid" component={ViewHomework} />
        <AuthorizedRoute path="/view/student/:id" component={ViewReport} />
        <AuthorizedRoute path="/view/tutor/:id" component={ViewTutor} />
        <AuthorizedRoute path="/view/submission/:id/:submissionid/:type" component={ViewSubmission} />
        <AppProvider>
          <AuthorizedRoute path="/home" component={parts[0] == "primary" ? PrimaryHome : parts[0] == "selectschool" ? SelectSchoolHome : HomeScreen} />
          <AuthorizedRoute path="/test" component={Test} />
          <AuthorizedRoute path="/homework" component={Homework} />
        </AppProvider>
        <Route path="" component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
