import React from 'react'
import { Button } from 'react-bootstrap'

function FAB({ onPress }) {
    return (
        <Button variant="dark"
            onClick={() => { onPress() }}
            style={{ height: 60, width: 60, borderRadius: 30, position: "fixed", bottom: 20, right: 20 }}>
            <i className="fa fa-plus" />
        </Button>
    )
}

export default FAB
