import React, { useEffect } from 'react'
import SideMenu from '../components/SideMenu'
import { Route } from 'react-router-dom'
import Students from './admin/Students'
import AdminDashboard from './admin/AdminDashboard'
import Grades from './admin/Grades'
import Subjects from './admin/Subjects'
import Tests from './admin/Tests'
import { AdminProvider } from '../utils/AdminProvider'
import Homework from './admin/Homework'
import Tutors from './admin/Tutors'
import EnrollmentForm from './admin/EnrollmentForm'
import { Button } from 'react-bootstrap'
import EquationGenerator from './admin/EquationGenerator'
import Billing from './admin/Billing'

function Admin(props) {

    return (
        <AdminProvider>
            <SideMenu />
            <div style={{ position: "absolute", top: 10, right: 20 }}>
                <Button href="/logout" variant="outline-dark">
                    <i className="fa fa-fw fa-sign-out sidemenuicon" style={{ fontSize: '1.75em', verticalAlign: "middle" }} />
                    Logout
                    </Button>
            </div>
            <div style={{ padding: 20 }} />
            <Route path={`${props.match.path}`} exact={true} component={AdminDashboard} />
            <Route path={`${props.match.path}/student`} component={Students} />
            <Route path={`${props.match.path}/grade`} component={Grades} />
            <Route path={`${props.match.path}/subject`} component={Subjects} />
            <Route path={`${props.match.path}/test`} exact component={Tests} />
            <Route path={`${props.match.path}/homework`} component={Homework} />
            <Route path={`${props.match.path}/tutors`} component={Tutors} />
            <Route path={`${props.match.path}/enroll`} component={EnrollmentForm} />
            <Route path={`${props.match.path}/equation`} component={EquationGenerator} />
            <Route path={`${props.match.path}/billing`} component={Billing} />
        </AdminProvider>
    )
}

export default Admin
