import React from 'react'
import '../css/nav.css'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

function NavBar() {
    const history = useHistory()
    const user = useSelector(state => state.user);
    return (
        <Navbar bg="white" variant="light" expand="lg" className="shadow" sticky="top">
            <Navbar.Brand onClick={() => { history.push('/home') }} className="btn">Point Cook Tutoring</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto mr-5">
                    <NavDropdown title={user.name} id="basic-nav-dropdown">
                        <NavDropdown.Item onClick={() => { history.push('/logout') }}>Logout</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default NavBar
