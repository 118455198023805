import React, { useEffect, useState } from 'react'
import { Container, Form, Button, Card, Modal } from 'react-bootstrap'
import ShowToast from '../../../utils/ShowToast'
import store from '../../../utils/store'
import makeRequest from '../../../utils/makeRequest'
import { AdminContext } from '../../../utils/AdminProvider'
import BtnDark from '../../../components/BtnDark'
function AddStudent(props) {
    const { grades } = React.useContext(AdminContext)
    const [name, setName] = useState("")
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [email, setEmail] = useState("")
    const [grade, setGrade] = useState(0)
    const [type, setType] = useState()
    const [confirmPassword, setConfirmPassword] = useState("")
    const [loading, setLoading] = useState(false)
    const { generateErrors } = React.useContext(AdminContext)
    const validate = () => {
        if (!name || name.trim() === "") {
            ShowToast('error', "Please enter name")
            return
        }
        if (!username || username.trim() === "") {
            ShowToast('error', "Please enter username")
            return
        }
        if (!email || email.trim() === "") {
            ShowToast('error', "Please enter email")
            return
        }
        if (grade === 0 || grade == null) {
            ShowToast('error', "Please select grade")
            return
        }
        if (!type || type.trim() == "" || type == null) {
            ShowToast('error', "Please select type")
            return
        }
        if (!password || password.trim() === "") {
            ShowToast('error', "Please select password")
            return
        }
        if (confirmPassword !== password) {
            ShowToast('error', "Passwords dont match")
            return
        }
        register()

    }
    const register = () => {
        let data = new FormData()
        data.append("name", name)
        data.append("username", username)
        data.append("password", password)
        data.append("grade_id", grade)
        data.append("email", email)
        data.append("type", type)
        setLoading(true)
        makeRequest(store.getState().user.token).post('/processRegister', data).then(res => {
            setLoading(false)
            if (res.data.status_code === 200) {
                props.onHide()
                ShowToast('success', res.data.message)
            } else {
                generateErrors(res.data.errors)
            }
        }).catch(error => {
            setLoading(false)
            ShowToast('error', "Something went wrong!")
        })
    }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Edit Student
          </Modal.Title>
            </Modal.Header><Modal.Body>
                <Form id="add-form">
                    <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="name" placeholder="Enter Name" onChange={(event) => { setName(event.target.value) }} />
                    </Form.Group>
                    <Form.Group controlId="username">
                        <Form.Label>Username</Form.Label>
                        <Form.Control type="username" placeholder="Enter username" onChange={(event) => { setUsername(event.target.value) }} />
                    </Form.Group>
                    <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Enter Email" onChange={(event) => { setEmail(event.target.value) }} />
                    </Form.Group>
                    <Form.Group controlId="grades_select">
                        <Form.Label>Grade</Form.Label>
                        <Form.Control as="select" onChange={(event) => { setGrade(event.target.value) }} placeholder="Grade" >
                            <option value={null}>Select Grade</option>
                            {grades.map(item => (
                                <option key={item.id} value={item.id}>{item.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="type_select">
                        <Form.Label>Type</Form.Label>
                        <Form.Control as="select" onChange={(event) => { setType(event.target.value) }} placeholder="Type" >
                            <option value={null}>Select Type</option>
                            <option value="primary">Primary</option>
                            <option value="selectschool">Select School</option>
                            <option value="secondary">Secondary</option>
                            <option value="vce">VCE</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" onChange={(event) => { setPassword(event.target.value) }} />
                    </Form.Group>
                    <Form.Group controlId="confirm_password">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" onChange={(event) => { setConfirmPassword(event.target.value) }} />
                    </Form.Group>
                    <BtnDark
                        title="Submit"
                        loading={loading}
                        onClick={(event) => {
                            event.preventDefault()
                            validate()
                        }} />
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default React.memo(AddStudent)
