import React, { useContext, useState, useEffect } from 'react'
import { Table, Container, Form, Col, Button, Modal } from 'react-bootstrap'
import BtnDark from '../../components/BtnDark'
import ShowToast from '../../utils/ShowToast'
import Switch from 'react-switch'
import EditBilling from './Billing/EditBilling'
function Billing() {
    const [data, setData] = useState([])
    const [name, setName] = useState("")
    const [sent, setSent] = useState("")
    const [method, setMethod] = useState("")
    const [received, setReceived] = useState("")
    const [filtered, setFiltered] = useState([])
    const [view, setView] = useState(false)
    const [single, setSingle] = useState("")
    const [paymentMethodModal, setPaymentMethodModal] = useState(false)
    const [selectedId, setSelectedId] = useState()
    const [selectedStatus, setSelectedStatus] = useState("-")
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState()
    const [item, setItem] = useState()
    const [edit, setEdit] = useState(false)
    const [receivedAmount, setReceivedAmount] = useState(0)
    let selected = []
    const getData = async () => {
        let response = await fetch('https://regapi.pointcooktutoring.com/api/getBillings');
        let responseJson = await response.json()
        setData(responseJson.data)
        setFiltered(responseJson.data)
    }
    const sendInvoice = async (id) => {
        let formData = new FormData()
        formData.append("id", id)
        let response = await fetch("https://regapi.pointcooktutoring.com/api/sendInvoice", { body: formData, method: "POST" })
        let responseJson = await response.json()
        if (responseJson.status_code == 200) {
            ShowToast("success", responseJson.message)
            let d = []
            data.forEach(x => {
                if (x.id === id) {
                    x.sent = 1
                    d.push(x)
                } else {
                    d.push(x)
                }
            })
            setData(d)
        } else {
            ShowToast("error", responseJson.message)
        }
    }
    useEffect(() => {
        getData()
    }, [])
    const filter = () => {
        if (name.length === 0 && sent === "" && method === "" && received === "") {
            setFiltered(data)
        } else {
            let d = [...data]
            if (name.length > 0) {
                d = d.filter(x => {
                    if (x.name.toLowerCase().indexOf(name.toLowerCase()) > -1) {
                        return true
                    } else {
                        return false
                    }
                })
            }
            if (sent !== "") {
                d = d.filter(x => {
                    if (x.sent == sent) {
                        return true
                    } else {
                        return false
                    }
                })
            }
            if (method !== "") {
                d = d.filter(x => {
                    if (x.payment_method === method) {
                        return true
                    } else {
                        return false
                    }
                })
            }
            if (received !== "") {
                d = d.filter(x => {
                    if (x.received == received) {
                        return true
                    } else {
                        return false
                    }
                })
            }
            setFiltered(d)
        }
    }
    useEffect(() => {
        filter()
    }, [data, name, sent, method, received])
    const sendAll = async () => {
        if (selected.length === 0) {
            ShowToast("error", "Please select people to send invoices")
            return
        }
        let formData = new FormData()
        formData.append("ids", JSON.stringify(selected))
        const response = await fetch("https://regapi.pointcooktutoring.com/api/sendAll", { method: "POST", body: formData })
        const responseJson = await response.json()
        if (responseJson.status_code === 200) {
            ShowToast("success", responseJson.message)
            let d = []
            data.forEach(x => {
                if (selected.includes(x.id)) {
                    x.sent = 1
                    d.push(x)
                } else {
                    d.push(x)
                }
            })
            setData(d)
            selected.forEach(element => {
                document.getElementById(`select${element}`).checked = false
            })
            selected = []
        } else {
            ShowToast("error", responseJson.message)
        }
    }
    const viewInvoice = async (id) => {
        const body = new FormData()
        body.append("id", id)
        const response = await fetch("https://regapi.pointcooktutoring.com/api/viewInvoice", { method: "POST", body: body })
        const responseJson = await response.json()
        if (responseJson.status_code === 200) {
            setSingle(responseJson.data)
            setView(true)
        } else {
            ShowToast("error", responseJson.message)
        }
    }
    const updatePaymentStatus = (id) => {
        setPaymentMethodModal(true)
        setSelectedId(id)
    }
    const paymentStatusUpdateRequest = async () => {
        let formData = new FormData()
        formData.append("id", selectedId)
        formData.append("payment_method", selectedPaymentMethod)
        formData.append("status", selectedStatus)
        if (selectedStatus === 2 && receivedAmount === 0) {
            alert("Please insert the partially rececived amount")
        }else{
            formData.append("received_amount",receivedAmount)
        }
        const response = await fetch('https://regapi.pointcooktutoring.com/api/updatePaymentStatus', { method: "POST", body: formData })
        const responseJson = await response.json()
        if (responseJson.status_code === 200) {
            ShowToast("success", responseJson.message)
            let updatedItem = responseJson.data
            let d = []
            data.forEach(x => {
                if (x.id === updatedItem.id) {
                    x.received = updatedItem.received
                    x.payment_method = updatedItem.payment_method
                    x.received_amount = updatedItem.received_amount
                    d.push(x)
                } else {
                    d.push(x)
                }
            })
            setData(d)
            setPaymentMethodModal(false)
        } else {
            ShowToast("error", responseJson.message)
        }
    }
    return (
        <Container fluid style={{ marginLeft: 64, overflowX: "scroll" }}>

            <div className="d-flex flex-column mt-3">
                <Form.Row style={{ alignItems: "center" }} className="mb-3">
                    <div>
                        <BtnDark onClick={(event) => {
                            event.preventDefault()
                            let c = window.confirm("Are you sure you want to send invoices to selected people?")
                            if (c) {
                                sendAll()
                            }
                        }} title="Send Selected" />
                    </div>
                </Form.Row>
                <Table striped bordered hover variant="dark">
                    <thead>
                        <tr>
                            <th>
                                <Form.Check type="checkbox" id="mainCheck" label="Select" onChange={(e) => {
                                    if (e.target.checked) {
                                        data.forEach(x => {
                                            selected.push(x.id)
                                            document.getElementById(`select${x.id}`).checked = true
                                        })
                                    } else {
                                        data.forEach(x => {
                                            document.getElementById(`select${x.id}`).checked = false
                                        })
                                        selected = []
                                    }
                                }} />
                            </th>
                            <th>
                                Name
                                <br />
                                <input placeholder="Search Student" onChange={e => setName(e.target.value)} />
                            </th>
                            <th>Subjects</th>
                            <th>Fee/Session</th>
                            <th>No. of Sessions</th>
                            <th>
                                Sent
                                <br />
                                <select onChange={(e) => setSent(e.target.value)}>
                                    <option value={""}>Invoice Sent</option>
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                </select>
                            </th>
                            <th>
                                Payment Method
                                <br />
                                <select onChange={(e) => setMethod(e.target.value)}>
                                    <option value={""}>Payment Method</option>
                                    <option value={"Bank"}>Bank</option>
                                    <option value={"Cash"}>Cash</option>
                                </select>
                            </th>
                            <th>
                                Payment Received
                                <br />
                                <select onChange={(e) => setReceived(e.target.value)}>
                                    <option value={""}>Payment Received</option>
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                    <option value={2}>Partial</option>
                                </select>
                            </th>
                            <th>Amount Recevied</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th className="text-center">options</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filtered.map((item, index) => (

                            <tr key={String(index)}>
                                <td><Form.Check type="checkbox" id={`select${item.id}`} onChange={(e) => {
                                    if (e.target.checked) {
                                        selected.push(item.id)
                                    } else {
                                        let newSel = selected.filter(x => {
                                            if (x === item.id) {
                                                return false
                                            } else {
                                                return true
                                            }
                                        })
                                        selected = newSel
                                    }
                                }} /></td>
                                <td>{item.name}</td>
                                <td>{item.subject}</td>
                                <td>${item.session_fee}</td>
                                <td>{item.num_of_sessions}</td>
                                <td>{item.sent ? "Yes" : "No"}</td>
                                <td>{item.payment_method}</td>
                                {/* <td><Switch checked={item.received == 1 ? true : false} onChange={(checked) => { updatePaymentStatus(item.id, checked) }} /></td> */}
                                <td>
                                    <i
                                        className="fa fa-edit mr-3"
                                        onClick={() => {
                                            updatePaymentStatus(item.id)
                                        }}
                                    />
                                    {item.received == 1 ? `Received` : item.received == 0 ? "Not Recevied" : `Partially Received`}
                                </td>
                                <td>${item.received_amount}</td>
                                <td>{item.phone}</td>
                                <td>{item.email}</td>
                                <td >
                                    <i
                                        className="fa fa-edit"
                                        onClick={() => {
                                            setItem(item)
                                            setEdit(true)
                                        }}
                                    />
                                    <i
                                        className="fa fa-eye ml-3"
                                        onClick={() => {
                                            viewInvoice(item.id)
                                        }} />
                                    <i
                                        className="fa fa-send ml-3"
                                        onClick={() => {
                                            sendInvoice(item.id)
                                        }} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            <Modal
                size="lg"
                show={view}
                onHide={() => setView(false)}
            >
                <Modal.Header closeButton />
                <Modal.Body>
                    <div dangerouslySetInnerHTML={{ __html: single }} />
                </Modal.Body>
            </Modal>
            <Modal
                show={paymentMethodModal}
                onHide={() => setPaymentMethodModal(false)}
                size="lg"
                centered>
                <Modal.Header closeButton>
                    Set Payment Status
                    </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <select
                            value={selectedPaymentMethod}
                            onChange={(e) => setSelectedPaymentMethod(e.target.value)}>
                            <option value={"-"}>Select payment method</option>
                            <option value={"Bank"}>Bank</option>
                            <option value={"Cash"}>Cash</option>
                        </select>
                    </Form.Group>
                    <Form.Group>
                        <select
                            value={selectedStatus}
                            onChange={(e) => { setSelectedStatus(e.target.value) }}>
                            <option value={"-"}>Select payment status</option>
                            <option value={0}>Not Received</option>
                            <option value={1}>Received</option>
                            <option value={2}>Partially Received</option>
                        </select>
                    </Form.Group>
                    {selectedStatus == 2 ?
                        <Form.Group>
                            <Form.Label>Amount</Form.Label>
                            <Form.Control type="number" placeholder="Received Amount" onChange={(event) => { setReceivedAmount(event.target.value) }} value={receivedAmount} />
                        </Form.Group>
                        :
                        null}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => paymentStatusUpdateRequest()}>Save</Button>
                </Modal.Footer>
            </Modal>
            <EditBilling
                show={edit}
                onHide={() => setEdit(false)}
                item={item}
                data={data}
                updateData={(d) => setData(d)}
            />
            {/* <FAB onPress={() => { setShow(true) }} /> */}
        </Container >
    )
}

export default Billing
