import React, { useState, useContext } from 'react'
import { Card, Container, Form, Modal } from 'react-bootstrap'
import Btn from '../../../components/Btn'
import { toast } from 'react-toastify'
import makeRequest from '../../../utils/makeRequest'
import store from '../../../utils/store'
import { AdminContext } from '../../../utils/AdminProvider'
import ShowToast from '../../../utils/ShowToast'
import BtnDark from '../../../components/BtnDark'

function AddGrade(props) {
    const [gradeName, setGradeName] = useState()
    const [loading, setLoading] = useState(false)
    const { generateErrors, setGrades } = useContext(AdminContext)

    const addGradeData = () => {
        let formData = new FormData()
        formData.append('name', gradeName)
        setLoading(true)
        makeRequest(store.getState().user.token).post('/addGrade', formData).then(res => {
            setLoading(false)
            if (res.data.status_code === 200) {
                ShowToast('success', res.data.message)
                setGrades(res.data.data)
                props.onHide()
            } else {
                ShowToast('error', res.data.message)
                generateErrors(res.data.errors)
            }
        }).catch(err => {
            setLoading(false)
            console.log(err);
            ShowToast('error', "Something Went Wrong!")
        })
    }
    return (
        <Modal
            {...props}
            size="m"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add Grade
      </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="add-grade-form">
                    <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="name" placeholder="Enter Grade Name (eg. 8th Grade)" onChange={(event) => { setGradeName(event.target.value) }} />
                    </Form.Group>
                    <BtnDark title="Submit" loading={loading} onClick={(event) => {
                        event.preventDefault()
                        if (gradeName != null) {
                            addGradeData()
                        } else {
                            toast.error("Please enter/select all the fields", {
                                autoClose: false,
                                position: "top-right",
                                draggable: true,
                                closeOnClick: true,
                                autoClose: 5000,
                                hideProgressBar: true
                            })
                        }
                    }} />
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default AddGrade
