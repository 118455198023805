import React from 'react'
import { Row, Container } from 'react-bootstrap'

function Chip({ item, onRemove, disabled }) {
    return (
        <div style={{
            padding: "4px 8px 4px 8px",
            borderRadius: 16,
            backgroundColor: "#292b2c",
            color: "#fff",
            margin: 4
        }}>
            <span
                style={{
                    marginRight: 8
                }}>{item.name}</span>
            {disabled ? null : <i className="fa fa-times" onClick={() => { onRemove(item.username) }} style={{ cursor: "pointer" }} />}
        </div>
    )
}

export default Chip
