import React, { useState, useContext, useEffect } from 'react'
import { Card, Container, Form, Modal, Spinner, Button } from 'react-bootstrap'
import Btn from '../../../components/Btn'
import { toast } from 'react-toastify'
import { AdminContext } from '../../../utils/AdminProvider'
import makeRequest from '../../../utils/makeRequest'
import store from '../../../utils/store'
import ShowToast from '../../../utils/ShowToast'
import BtnDark from '../../../components/BtnDark'
function AddHomeworkQuestions(props) {
    const { grades, homework, subjects, generateErrors } = useContext(AdminContext)
    const [file, setFile] = useState()
    const [homeworkId, setHomeworkId] = useState()
    const [loading, setLoading] = useState(false)
    const addHomeworkQuestions = () => {
        let formData = new FormData()
        formData.append('homework_id', homeworkId)
        formData.append('file', file)
        setLoading(true)
        makeRequest(store.getState().user.token, 'multipart/form-data').post('/addHomeworkQuestions', formData).then(res => {
            setLoading(false)
            if (res.data.status_code === 200) {
                ShowToast('success', res.data.message)
                document.getElementById('add-question-form').reset()
                setFile()
                setHomeworkId()
            } else {
                ShowToast('error', res.data.message)
                generateErrors(res.data.errors)
            }
        }).catch(err => {
            setLoading(false)
            ShowToast('error', "Something Went Wrong!")
        })
    }

    useEffect(() => {
        if (props.item) {
            let item = props.item
            setHomeworkId(item.id)
        }
    }, [props.item])
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header>
                <Modal.Title>Add Homework Questions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="add-question-form">
                    <Form.Group controlId="subjects_select">
                        {/* <Form.Label>Homework</Form.Label> */}
                        {/* <Form.Control as="select" onChange={(event) => { setHomeworkId(event.target.value) }} placeholder="Test" >
                            <option value={0}>Select Homework</option>
                            {getHomeworkOptions()}
                        </Form.Control> */}
                    </Form.Group>
                    <Form.Group>
                        <Form.File id="file" label="Select Excel file" onChange={(event) => { setFile(event.target.files[0]) }} />
                    </Form.Group>
                </Form>
                <a href={`${process.env.PUBLIC_URL}/Test.xlsx`} className="m-3">Download sample excel file</a>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide} variant="dark">Close</Button>
                {loading ? <Spinner animation="border" variant="dark" /> : <Button onClick={(event) => {
                    event.preventDefault()
                    if (homeworkId != null && homeworkId != 0 && file != null) {
                        addHomeworkQuestions()
                        props.onHide()
                    } else {
                        toast.error("Please enter/select all the fields", {
                            autoClose: false,
                            position: "top-right",
                            draggable: true,
                            closeOnClick: true,
                            autoClose: 5000,
                            hideProgressBar: true
                        })
                    }
                }}>Upload</Button>}
            </Modal.Footer>
        </Modal>
    )
}

export default AddHomeworkQuestions


