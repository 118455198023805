import React, { useContext, useState, useEffect } from 'react'
import { Container, Form, Table } from 'react-bootstrap'
import '../../css/tabs.css'
import AddTest from './Tests/AddTest'
import ViewTest from './Tests/ViewTest'
import AddQuestions from './Tests/AddQuestions'
import { AdminContext } from '../../utils/AdminProvider'
import FAB from '../../components/FAB'
import BtnDark from '../../components/BtnDark'
import EditTest from './Tests/EditTest'
import { useHistory } from 'react-router-dom'
import makeRequest from '../../utils/makeRequest'
import ShowToast from '../../utils/ShowToast'
import store from '../../utils/store'
function Tests() {
    const { tests, grades, subjects } = useContext(AdminContext)
    const [t, setT] = useState([])
    const [name, setName] = useState("")
    const [gradeId, setGradeId] = useState(null)
    const [subjectId, setSubjectId] = useState(null)
    const [selected, setSelected] = useState()
    const [show, setShow] = useState(false)
    const [edit, setEdit] = useState(false)
    const [questionDialog, setQuestionDialog] = useState(false)
    const history = useHistory()
    const filter = () => {
        let tt = tests.filter((value) => {
            if (gradeId != null) {
                if (subjectId != null) {
                    if (name.length > 0) {
                        if (value.grade_id == gradeId && value.subject_id == subjectId && value.name.indexOf(name) > -1) {
                            return true
                        }
                    } else {
                        if (value.grade_id == gradeId && value.subject_id == subjectId) {
                            return true
                        }
                    }
                } else {
                    if (name.length > 0) {
                        if (value.grade_id == gradeId && value.name.indexOf(name) > -1) {
                            return true
                        }
                    } else {
                        if (value.grade_id == gradeId) {
                            return true
                        }
                    }
                }
            } else if (name.length > 0) {
                if (value.name.indexOf(name) > -1) {
                    return true
                }
            } else {
                return true
            }
        })
        setT(tt)
    }
    const clear = () => {
        setT([...tests])
    }
    useEffect(() => {
        if (tests.length > 0) {
            filter()
        }
    }, [tests])
    const download = async (id) => {
        let data = new FormData()
        data.append("id", id)
        data.append("homework", "0")
        const response = await makeRequest(store.getState().user.token).post('/downloadExcel', data)
        if (response.data.status_code == 200) {
            window.location.href = response.data.data
        } else {
            ShowToast("error", response.data.message)
        }
    }
    return (
        <Container className="ccontainer">
            <AddTest
                show={show}
                onHide={() => { setShow(false) }}
            />
            <EditTest
                show={edit}
                onHide={() => { setEdit(false) }}
                item={selected}
            />
            <AddQuestions
                show={questionDialog}
                onHide={() => { setQuestionDialog(false) }}
                item={selected}
            />
            <div className="d-flex flex-column mt-3 align-items-center">
                <Form>
                    <Form.Row>
                        <Form.Group >
                            <Form.Control placeholder="Test name" value={name} onChange={(event) => { setName(event.target.value) }} />
                        </Form.Group>
                        <Form.Group controlId="grade_select" style={{ marginLeft: 16 }}>
                            <Form.Control as="select" onChange={(event) => {
                                setGradeId(event.target.value)
                                setSubjectId(null)
                            }} placeholder="Grade" >
                                <option value={null}>Select Grade</option>
                                {grades.map(item => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="subject_select" style={{ marginLeft: 16 }}>
                            <Form.Control as="select" onChange={(event) => { setSubjectId(event.target.value) }} placeholder="Subject" >
                                <option value={null}>Select Subject</option>
                                {subjects.filter((value) => {
                                    if (value.grade_id == gradeId) {
                                        return true
                                    } else {
                                        return false
                                    }
                                }).map(item => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <div style={{ marginLeft: 16 }}>
                            <BtnDark onClick={(event) => {
                                event.preventDefault()
                                filter()
                            }} title="Search" />
                        </div>
                        <div style={{ marginLeft: 16 }}>
                            <BtnDark onClick={(event) => {
                                event.preventDefault()
                                clear()
                            }} title="Clear" />
                        </div>
                    </Form.Row>
                </Form>
                <Table striped bordered hover variant="dark">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Grade</th>
                            <th>Subject</th>
                            <th>Active</th>
                            <th className="text-center">options</th>
                        </tr>
                    </thead>
                    <tbody>
                        {t.map((item, index) => {
                            let gradeName = grades.find(x => x.id === item.grade_id).name
                            let subjectName = subjects.find(x => x.id === item.subject_id).name
                            return (
                                <tr key={String(index)}>
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>
                                    <td>{gradeName}</td>
                                    <td>{subjectName}</td>
                                    <td>{item.active}</td>
                                    <td >
                                        <i
                                            className="fa fa-edit"
                                            onClick={() => {
                                                setSelected(item)
                                                setEdit(true)
                                            }} />
                                        <i
                                            className="fa fa-plus ml-3"
                                            onClick={() => {
                                                setSelected(item)
                                                setQuestionDialog(true)
                                            }} />
                                        <a href={`/view/test/${gradeName}/${subjectName}/${item.id}`} target="_blank">
                                            <i className="fa fa-eye ml-3" style={{ color: "#fff" }} />
                                        </a>
                                        <i className="fa fa-download ml-3" style={{ color: "#fff" }} onClick={() => download(item.id)} />
                                    </td>
                                </tr>
                            )
                        }
                        )}
                    </tbody>
                </Table>
            </div>
            <FAB onPress={() => { setShow(true) }} />
        </Container>
    )
}

export default Tests
