import React, { useContext, useState, useEffect } from 'react'
import { AdminContext } from '../../utils/AdminProvider'
import { Table, Container, Form, Col, Button } from 'react-bootstrap'
import BtnDark from '../../components/BtnDark'
import FAB from '../../components/FAB'
import AddGrade from './Grades/AddGrade'
import EditGrade from './Grades/EditGrade'

function Grades() {
    const { grades } = useContext(AdminContext)
    const [g, setG] = useState([...grades])
    const [name, setName] = useState("")
    const [selected, setSelected] = useState()
    const [edit, setEdit] = useState(false)
    const [show, setShow] = useState(false)
    const filter = () => {
        if (name.length > 0) {
            let newG = []
            grades.forEach(element => {
                if (element.name.indexOf(name) > -1) {
                    newG.push(element)
                }
                setG(newG)
            });
        } else {
            setG([...grades])
        }
    }
    const clear = () => {
        setG([...grades])
    }
    useEffect(() => {
        filter()
    }, [grades])
    return (
        <Container>
            <AddGrade
                show={show}
                onHide={() => { setShow(false) }} />
            <EditGrade
                show={edit}
                onHide={() => { setEdit(false) }}
                item={selected}
            />
            <div className="d-flex flex-column mt-3 align-items-center">
                <Form>
                    <Form.Row>
                        <Form.Group >
                            <Form.Control placeholder="Grade name" value={name} onChange={(event) => { setName(event.target.value) }} />
                        </Form.Group>
                        <div style={{ marginLeft: 16 }}>
                            <BtnDark onClick={(event) => {
                                event.preventDefault()
                                filter()
                            }} title="Search" />
                        </div>
                        <div style={{ marginLeft: 16 }}>
                            <BtnDark onClick={(event) => {
                                event.preventDefault()
                                clear()
                            }} title="Clear" />
                        </div>
                    </Form.Row>
                </Form>
                <Table striped bordered hover variant="dark">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th className="text-center">options</th>
                        </tr>
                    </thead>
                    <tbody>
                        {g.map((item, index) => (

                            <tr key={String(index)}>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td >
                                    <i
                                        className="fa fa-edit"
                                        onClick={() => {
                                            setSelected(item)
                                            setEdit(true)
                                        }} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            <FAB onPress={() => { setShow(true) }} />
        </Container>
    )
}

export default Grades
