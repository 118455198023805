import React, { useState } from 'react'
import { Modal, Form } from 'react-bootstrap'
import ShowToast from '../../../utils/ShowToast'
import BtnDark from '../../../components/BtnDark'
import makeRequest from '../../../utils/makeRequest'
import store from '../../../utils/store'
function AddSession(props) {
    const [loading, setLoading] = useState(false)
    const [week, setWeek] = useState()
    const [name, setName] = useState("")
    const [day, setDay] = useState()
    const addSessionData = () => {
        let data = new FormData()
        data.append('week', week)
        data.append('name', name)
        data.append("tutor_id", props.tutor_id)
        data.append("day", day)
        setLoading(true)
        makeRequest(store.getState().user.token).post('/addSession', data).then(res => {
            setLoading(false)
            if (res.data.status_code == 200) {
                ShowToast('success', res.data.message)
                props.myfun(res.data.data)
                props.onHide()
            } else {
                ShowToast('error', res.data.message)
            }
        }).catch(err => {
            setLoading(false)
            ShowToast('error', err)
        })
    }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Add Session</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Session Name</Form.Label>
                        <Form.Control placeholder="Session Name" onChange={event => setName(event.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Week</Form.Label>
                        <Form.Control type="number" placeholder="Week" onChange={event => setWeek(event.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Day</Form.Label>
                        <Form.Control as="select" onChange={(event) => { setDay(event.target.value) }}>
                            <option value="">Select Day</option>
                            <option value="Monday">Monday</option>
                            <option value="Tuesday">Tuesday</option>
                            <option value="Wednesday">Wednesday</option>
                            <option value="Thursday">Thursday</option>
                            <option value="Friday">Friday</option>
                            <option value="Saturday">Saturday</option>
                            <option value="Sunday">Sunday</option>
                        </Form.Control>
                    </Form.Group>
                    <BtnDark title="Submit" loading={loading} onClick={(event) => {
                        event.preventDefault()
                        if (week && name.trim() != "" && day && day != "") {
                            addSessionData()
                        } else {
                            ShowToast('error', "Please enter/select all the fields")
                        }
                    }} />
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default AddSession