import React, { useEffect, useState, useContext } from 'react'
import '../css/home.css'
import Button from 'react-bootstrap/Button'
import { Col, Form, Row } from 'react-bootstrap'
import makeRequest from '../utils/makeRequest'
import BtnDark from '../components/BtnDark'
import ShowToast from '../utils/ShowToast'
import { useDispatch } from 'react-redux'
import { logout } from '../actions/loginActions'

function PrimaryWelcome() {
    const dispatch = useDispatch()
    const [data, setData] = useState()
    const [name, setName] = useState("")
    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [grade_id, setGrade_id] = useState(0)
    const [loading, setLoading] = useState(false)
    const [showTrial, setShowTrial] = useState(false)
    const [phone, setPhone] = useState("")
    useEffect(() => {
        dispatch(logout())
        setLoading(true)
        makeRequest().get("/getTrialData").then(res => {
            if (res.data.status_code == 200) {
                setShowTrial(res.data.trial_on)
                setData(res.data.data)
                setLoading(false)
            } else {
                setLoading(false)
            }
        }).catch(err => {
            setLoading(false)
        })
    }, [])
    const generateErrors = (data) => {
        for (var key in data) {
            data[key].map((item, index) => {
                ShowToast('error', item)
            })
        }
    }
    const register = () => {
        if (name.trim() == "") {
            ShowToast("error", "Please enter your full name")
            return
        }
        if (username.trim() == "") {
            ShowToast("error", "Please enter username")
            return
        }
        if (email.trim() == "") {
            ShowToast("error", "Please enter your Email")
            return
        }
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            ShowToast("error", "Please enter correct email")
            return
        }
        if (!/^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/.test(phone)) {
            ShowToast("error", "Please enter correct contact number")
            return
        }
        if (password.trim() == "") {
            ShowToast("error", "Please enter password")
            return
        }
        if (confirmPassword !== password) {
            ShowToast("error", "Confirm password does not match with password")
            return
        }
        if (grade_id == 0) {
            ShowToast("error", "Please select Grade")
            return
        }
        setLoading(true)
        let data = new FormData()
        data.append("name", name)
        data.append("username", username)
        data.append("email", email)
        data.append("phone",phone)
        data.append("password", password)
        data.append("password_confirmation", confirmPassword)
        data.append("grade_id", grade_id)
        makeRequest().post('/trialRegister', data).then(res => {
            if (res.data.status_code == 200) {
                ShowToast("success", res.data.message)
                setTimeout(() => {
                    window.location.href = "/login"
                }, 3000);
            } else {
                if (res.data.errors) {
                    generateErrors(res.data.errors)
                } else {
                    ShowToast("error", res.data.message)
                }
            }
        }).catch(err => {
            ShowToast("error", "Something went wrong!")
        }).finally(() => {
            setLoading(false)
        })
    }
    return (
        <div style={{ backgroundImage: `url(${require('../resources/images/primaryBg.jpg')})`, backgroundSize: "100% 100%", overflowX: "hidden" }}>
            <Row className="d-flex min-vh-100 flex-row" xs={1} lg={3}>
                <Col className="bg-white d-flex p-5 flex-column">
                    <div className="align-items-center d-flex flex-column mb-2">
                        <img src={require('../resources/logo/welcomeLogo.jpg')} alt="point-cook-tutoring"
                            style={{ objectFit: "contain", width: "30%" }} />
                        <strong>Welcome to</strong>
                        <h5>Point Cook Tutoring</h5>
                    </div>
                    <div className="align-items-center d-flex flex-column mb-2">
                        {showTrial ? <p>Already a student?</p> : null}
                        <Button variant="outline-dark" href="/login">
                            Login
                        </Button>
                    </div>
                    {showTrial ?
                        <div>
                            <div className="separator">Or</div>

                            <div className="mt-2">
                                <div className="align-items-center d-flex flex-column">
                                    <h5>Create Account</h5>
                                    <p>Start a free trial</p>
                                </div>
                                <Form>
                                    <Form.Group>
                                        <Form.Control placeholder="Full Name" value={name} onChange={event => setName(event.target.value)} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Control placeholder="Username" value={username} onChange={event => setUsername(event.target.value)} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Control placeholder="Email" value={email} onChange={event => setEmail(event.target.value)} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Control placeholder="Contact Number" value={phone} onChange={event => setPhone(event.target.value)} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Control type="password" placeholder="Password" value={password} onChange={event => setPassword(event.target.value)} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Control type="password" placeholder="Confirm Password" value={confirmPassword} onChange={event => setConfirmPassword(event.target.value)} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Control as="select" value={grade_id} onChange={event => setGrade_id(event.target.value)}>
                                            <option value={0}>Select Grade</option>
                                            {data ? data.map(item => (
                                                <option value={item.id} key={`grade${item.id}`}>{item.name}</option>
                                            )) : null}
                                        </Form.Control>
                                    </Form.Group>
                                    <div className="d-flex justify-content-center">
                                        <BtnDark
                                            title="Register"
                                            loading={loading}
                                            onClick={(event) => {
                                                event.preventDefault()
                                                register()
                                            }} />
                                    </div>
                                </Form>
                            </div>
                        </div>
                        :
                        null
                    }
                </Col>
                <Col>
                </Col>
                <Col>
                </Col>
                {/* <p className="title">Point Cook Tutoring</p>
                <p>(Primary)</p>
                <Button variant="outline-secondary" href="/login">Get Started</Button> */}
            </Row>
        </div>
    )
}

export default PrimaryWelcome
