import React, { useState } from 'react'
import { Form, InputGroup, Modal, Button, Row, Col } from 'react-bootstrap'
import { url } from '../utils/Constants'
import makeRequest from '../utils/makeRequest'
import ShowToast from '../utils/ShowToast'

export default function ForgotUsername({ show, hide }) {
    const [email, setEmail] = useState()
    const sendRequest = async () => {
        const data = new FormData();
        data.append("email", email)
        let response = await makeRequest().post("/forgotUsername",data)
        if(response.data.status_code == 200){
            ShowToast("success", response.data.data)
            hide()
        }else{
            ShowToast("error", response.data.data)
        }
        
    }
    return (
        <Modal
            show={show}
            onHide={hide}
            size="md"
            centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    Forgot username
                    </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text>Email</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control value={email} type="email" onChange={(e) => setEmail(e.target.value)} />
                </InputGroup>
                <Row className="justify-content-center mt-3">
                    <Button variant="outline-dark" onClick={sendRequest}>Submit</Button>
                </Row>
            </Modal.Body>
        </Modal>
    )
}
