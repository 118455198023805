import React, { useState, useContext } from 'react'
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { AppContext } from '../utils/AppProvider';
function PrimarySidemenu() {
    const history = useHistory()
    const location = useLocation()
    const [expanded, setExpanded] = useState(false)
    const user = useSelector(state => state.user);
    const { homework } = useContext(AppContext)
    return (
        <SideNav
            onMouseEnter={() => { setExpanded(true) }}
            onMouseLeave={() => { setExpanded(false) }}
            onToggle={() => { }}
            expanded={expanded}
            onSelect={(selected) => {
                // Add your code here
                history.push(selected)
            }} style={{ backgroundColor: "#292b2c", position: "fixed" }}>
            <SideNav.Toggle onClick={() => { setExpanded(!expanded) }} />
            <SideNav.Nav defaultSelected={location.pathname}>
                <div style={{ flex: 1, display: expanded ? "flex" : "none", height: 200, width: "100%", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                    <p style={{ color: "#fff" }}>Point Cook Tutoring</p>
                    {user.image == "" ?
                        <div className="initials">
                            <h1 style={{ marginTop: 5 }}>{user.name.split(" ").map((n) => n[0]).join("")}</h1>
                            <div className="imageEdit" onClick={() => window.location.href = "/home/profile"}>
                                <span>Edit</span>
                                <i className="fa fa-pencil" />
                            </div>
                        </div>
                        :
                        <div className="initials">
                            <img src={user.image} className="profileImage" />
                            <div className="imageEdit" onClick={() => window.location.href = "/home/profile"}>
                                <span>Edit</span>
                                <i className="fa fa-pencil" />
                            </div>
                        </div>
                    }
                    <p style={{ color: "#fff", marginTop: 10 }}>{user.name}</p>
                </div>
                <div style={{ flex: 1, display: !expanded ? "flex" : "none", height: 200, width: "100%", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                    <p style={{ color: "#fff" }}> </p>
                    {user.image == "" ?
                        <div className="initialsSmall">
                            <h5 style={{ marginTop: 5 }}>{user.name.split(" ").map((n) => n[0]).join("")}</h5>
                        </div> :
                        <img src={user.image} className="profileImageSmall" />}
                    <p style={{ color: "#fff", marginTop: 10 }}> </p>
                </div>

                <div style={{ marginTop: 24 }}></div>
                <NavItem eventKey="/home">
                    <NavIcon>
                        <i className="fa fa-fw fa-home" style={{ fontSize: '1.75em', verticalAlign: "middle", color: "#fff" }} />
                    </NavIcon>
                    <NavText style={{ color: "#fff" }}>Home</NavText>
                </NavItem>
                {/* Subjects */}
                {homework.map((item, index) => {
                    if (item.homeworks.length == 0) return
                    return (
                        <NavItem eventKey={`/home/subject/${item.subject_id}`} key={`Subject${item.subject_id}`}>
                            <NavIcon><i className="fa fa-fw fa-book" style={{ fontSize: '1.75em', verticalAlign: "middle", color: "#fff" }} /></NavIcon>
                            <NavText style={{ color: "#fff" }}>{item.subject_name}</NavText>
                        </NavItem>
                    )
                })}
                {/* <NavItem eventKey="/home/scholarship">
                    <NavIcon>
                        <i className="fa fa-fw fa-book" style={{ fontSize: '1.75em', verticalAlign: "middle", color: "#fff" }} />
                    </NavIcon>
                    <NavText style={{ color: "#fff" }}>Scholarship Exams</NavText>
            </NavItem> */}
                <NavItem eventKey="/home/writing">
                    <NavIcon>
                        <i className="fa fa-fw fa-book" style={{ fontSize: '1.75em', verticalAlign: "middle", color: "#fff" }} />
                    </NavIcon>
                    <NavText style={{ color: "#fff" }}>Writing</NavText>
                </NavItem>
                <div style={{ marginTop: 24 }}></div>
                <NavItem eventKey="/logout">
                    <NavIcon>
                        <i className="fa fa-fw fa-sign-out sidemenuicon" style={{ fontSize: '1.75em', verticalAlign: "middle", color: "#fff" }} />
                    </NavIcon>
                    <NavText style={{ color: "#fff" }}>Logout</NavText>
                </NavItem>
            </SideNav.Nav>
        </SideNav >
    )
}

export default PrimarySidemenu
