export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_REQUEST_SUCCESS = "LOGIN_REQUEST_SUCCESS"
export const LOGIN_REQUEST_FAILURE = "LOGIN_REQUEST_FAILURE"
export const LOG_OUT = "LOGOUT"
export const REGISTER_REQUEST = "REGISTER_REQUEST"
export const REGISTER_SUCCESS = "REGISTER_SUCCESS"
export const REGISTER_FAILURE = "REGISTER_FAILURE"
export const REGISTER_RESET = "REGISTER_RESET"
export const GET_GRADE_DATA = "GET_GRADE_DATA"
export const GET_GRADE_DATA_SUCCESS = "GET_GRADE_DATA_SUCCESS"
export const GET_GRADE_DATA_FAILURE = "GET_GRADE_DATA_FAILURE"
export const GET_MASTER_DATA = "GET_MASTER_DATA"
export const GET_MASTER_DATA_SUCCESS = "GET_MASTER_DATA_SUCCESS"
export const GET_MASTER_DATA_FAILURE = "GET_MASTER_DATA_FAILURE"
export const GET_TEST_DATA = "GET_TEST_DATA"
export const GET_TEST_DATA_SUCCESS = "GET_TEST_DATA_SUCCESS"
export const GET_TEST_DATA_FAILURE = "GET_TEST_DATA_FAILURE"
export const SEND_TEST_DATA = "SEND_TEST_DATA"
export const SEND_TEST_DATA_SUCCESS = "SEND_TEST_DATA_SUCCESS"
export const SEND_TEST_DATA_FAILURE = "SEND_TEST_DATA_FAILURE"
export const ADD_GRADE = "ADD_GRADE"
export const ADD_GRADE_SUCCESSFUL = "ADD_GRADE_SUCCESSFUL"
export const ADD_GRADE_FAIL = "ADD_GRADE_FAIL"
export const RESET_GRADE_SHOW = "RESET_GRADE_SHOW"
export const GET_SUBJECT = "GET_SUBJECT"
export const GET_SUBJECT_SUCCESS = "GET_SUBJECT_SUCCESS"
export const GET_SUBJECT_FAIL = "GET_SUBJECT_FAIL"
export const ADD_SUBJECT = "ADD_SUBJECT"
export const ADD_SUBJECT_SUCCESS = "ADD_SUBJECT_SUCCESS"
export const ADD_SUBJECT_FAIL = "ADD_SUBJECT_FAIL"
export const RESET_SUBJECT_SHOW = "RESET_SUBJECT_SHOW"
export const GET_TESTS = "GET_TESTS"
export const GET_TESTS_SUCCESS = "GET_TESTS_SUCCESS"
export const GET_TESTS_FAIL = "GET_TESTS_FAIL"
export const ADD_TESTS = "ADD_TESTS"
export const ADD_TESTS_SUCCESS = "ADD_TESTS_SUCCESS"
export const ADD_TESTS_FAIL = "ADD_TESTS_FAIL"
export const RESET_TESTS_SHOW = "RESET_TESTS_SHOW"
export const ADD_QUESTIONS = "ADD_QUESTIONS"
export const ADD_QUESTIONS_SUCCESS = "ADD_QUESTIONS_SUCCESS"
export const ADD_QUESTIONS_FAIL = "ADD_QUESTIONS_FAIL"
export const RESET_QUESTIONS_SHOW = "RESET_QUESTIONS_SHOW"