import React, { useContext, useState } from 'react'
import { Container, Form, Card, Button } from 'react-bootstrap'
import { AdminContext } from '../../utils/AdminProvider'

function EnrollmentForm() {
    const { grades } = useContext(AdminContext)
    const [data, setData] = useState({
        grade_id:0
    })
    function handleChange(evt) {
        const value = evt.target.value;
        setData({
            ...data,
            [evt.target.name]: value
        });
    }
    return (
        <Container>
            <Card className="shadow rounded pl-5 pr-5 pt-2 pb-2 mt-2 d-flex">
                <Form>
                    <Form.Group>
                        <Form.Control placeholder="Full Name" name="name" required onChange={handleChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control as="select" name="grade_id" required onChange={handleChange} value={data.grade_id}>
                            <option value={0}>Select Grade</option>
                            {grades.map(item => (
                                <option value={item.id} key={item.name}>{item.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Control placeholder="School" name="school" required />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control placeholder="Birth Date (DD/MM/YYYY)" name="birthdate" required />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control placeholder="Mobile Number" name="mobile" required type="number" onChange={handleChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control placeholder="Address" name="address" required as="textarea" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control placeholder="Comments/Remarks" name="comments" as="textarea" />
                    </Form.Group>
                    <div className="separator">Parent Carer 1</div>
                    <Form.Group>
                        <Form.Control placeholder="Full Name" name="carer1name" required />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control placeholder="Mobile Number" name="carer1mobile" required type="number" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control placeholder="Email Address" name="carer1email" required type="email" />
                    </Form.Group>
                    <div className="separator">Parent Carer 2</div>
                    <Form.Group>
                        <Form.Control placeholder="Full Name" name="carer2name" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control placeholder="Mobile Number" name="carer2mobile" type="number" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control placeholder="Email Address" name="carer2email" type="email" />
                    </Form.Group>
                    <div className="d-flex justify-content-center">
                        <Button variant="outline-dark" type="submit" className="align-self-center">Submit</Button>
                    </div>
                </Form>
            </Card>
        </Container>
    )
}

export default EnrollmentForm
