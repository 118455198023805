import React from 'react'
import '../css/home.css'
import Button from 'react-bootstrap/Button'
import { Row, Col } from 'react-bootstrap'
function SelectSchoolWelcome() {
    return (
        <div style={{ backgroundImage: `url(${require('../resources/images/primaryBg.jpg')})`, backgroundSize: "100% 100%" }}>
            <Row className="d-flex min-vh-100 flex-row" xs={1} lg={3}>
                <Col className="bg-white d-flex p-5 flex-column">
                    <div className="align-items-center d-flex flex-column mt-2 mb-2">
                        <img src={require('../resources/logo/welcomeLogo.jpg')} alt="point-cook-tutoring"
                            style={{ objectFit: "contain", width: "100%" }} />
                        <h5>Welcome to</h5>
                        <h3>Point Cook Tutoring</h3>
                        <h6>A BETTER PLACE TO LEARN</h6>
                    </div>
                    <div className="align-items-center d-flex flex-column mt-2 mb-2">
                        <Button variant="outline-dark" href="/login">
                            Login
                    </Button>
                    </div>

                </Col>
                <Col>
                </Col>
                <Col>
                </Col>
            </Row>
        </div>
    )
}

export default SelectSchoolWelcome
