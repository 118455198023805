import React, { useEffect, useState } from 'react'
import { Container, Card, Row, Col, Table, Form } from 'react-bootstrap'
import '../../css/gradient.css'
import makeRequest from '../../utils/makeRequest'
import store from '../../utils/store'
import moment from 'moment'
function AdminDashboard() {
    const [data, setData] = useState()
    useEffect(() => {
        makeRequest(store.getState().user.token).get('/initialData').then(res => {
            if (res.data.status_code == 200) {
                setData(res.data)
            }
        })
    }, [])
    const [type, setType] = useState(0)
    return (
        <Container className="mt-5">
            <Row>
                <Card border="light" className="m-2 shadow-lg mb-5 bg-white rounded gradient1" as={Col}>
                    <Card.Body className="d-flex justify-content-between align-items-center">
                        <i className="fa fa-user fa-3x" style={{ color: "#fff" }} />
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <p className="statLabel">Students</p>
                            <h3 className="stat">{data ? data.students : "-"}</h3>
                        </div>
                    </Card.Body>
                </Card>
                <Card border="light" className="m-2 shadow-lg mb-5 bg-white rounded gradient2" as={Col}>
                    <Card.Body className="d-flex justify-content-between align-items-center">
                        <i className="fa fa-file-text fa-3x" style={{ color: "#fff" }} />
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <p className="statLabel">Tests submitted</p>
                            <h3 className="stat">{data ? data.tests : "-"}</h3>
                        </div>
                    </Card.Body>
                </Card>
                <Card border="light" className="m-2 shadow-lg mb-5 bg-white rounded gradient3" as={Col}>
                    <Card.Body className="d-flex justify-content-between align-items-center">
                        <i className="fa fa-book fa-3x" style={{ color: "#fff" }} />
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <p className="statLabel">Homeworks submitted</p>
                            <h3 className="stat">{data ? data.homeworks : "-"}</h3>
                        </div>
                    </Card.Body>
                </Card>
            </Row>
            <Card border="light" className="shadow-lg mb-5 bg-white rounded">
                <Card.Body>
                    <Card.Title>
                        Updates
                            <Form.Control as="select" onChange={(event) => { setType(event.target.value) }} placeholder="Type" >
                            <option value={0}>Homework</option>
                            <option value={1}>Test</option>
                        </Form.Control>
                    </Card.Title>
                    {type == 0 ?
                        <Table bordered responsive hover>
                            <thead>
                                <tr>
                                    <th>Student Name</th>
                                    <th>Homework</th>
                                    <th>Submission Date/Time</th>
                                    <th>Score</th>
                                    <th>Total Questions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.homework_updates.map((item, index) => {
                                    console.log(item);
                                    return (
                                        <tr key={`homeworkUpdates${index}`}>
                                            <td>{item.username}({item.gradename})</td>
                                            <td>{item.subjectname}-{item.homeworkname} (Week {item.week})</td>
                                            <td>{moment(item.created_at).format('MMMM Do YYYY, HH:mm')}</td>
                                            <td>{item.score}</td>
                                            <td>{item.total_questions}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        :
                        <Table bordered responsive hover>
                            <thead>
                                <tr>
                                    <th>Student Name</th>
                                    <th>Test</th>
                                    <th>Submission Date/Time</th>
                                    <th>Score</th>
                                    <th>Total Questions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.test_updates.map((item, index) => {
                                    console.log(item);
                                    return (
                                        <tr key={`testUpdates${index}`}>
                                            <td>{item.username}({item.gradename})</td>
                                            <td>{item.subjectname}-{item.testname}</td>
                                            <td>{moment(item.created_at).format('MMMM Do YYYY, HH:mm')}</td>
                                            <td>{item.score}</td>
                                            <td>{item.total_questions}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    }
                </Card.Body>
            </Card>
        </Container>
    )
}

export default AdminDashboard
