import React from 'react'
import { Button, Spinner } from 'react-bootstrap'

function BtnDark({ title, onClick, loading }) {
    return (
        <div>
            {loading ? <Spinner animation="border" variant="dark" />
                : <Button variant="outline-dark" type="submit" onClick={(event) => { onClick(event) }}>{title}</Button>}
        </div>
    )
}

export default BtnDark
