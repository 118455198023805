import React, { useState, useEffect, useContext } from 'react'
import { Card, Container, Form, Modal } from 'react-bootstrap'
import { AdminContext } from '../../../utils/AdminProvider'
import ShowToast from '../../../utils/ShowToast'
import makeRequest from '../../../utils/makeRequest'
import store from '../../../utils/store'
import BtnDark from '../../../components/BtnDark'
function EditSubject(props) {
    const { grades, generateErrors, setSubjects } = useContext(AdminContext)
    const [id, setId] = useState()
    const [subjectName, setSubjectName] = useState()
    const [gradeId, setGradeId] = useState()
    const [loading, setLoading] = useState(false)
    const editSubjectData = () => {
        let formData = new FormData()
        formData.append('id', id)
        formData.append('name', subjectName)
        formData.append('grade_id', gradeId)
        setLoading(true)
        makeRequest(store.getState().user.token).post('/editSubject', formData).then(res => {
            setLoading(false)
            if (res.data.status_code === 200) {
                ShowToast('success', res.data.message)
                setSubjects(res.data.data)
                props.onHide()
            } else {
                ShowToast('error', res.data.message)
                generateErrors(res.data.errors)
            }
        }).catch(err => {
            setLoading(false)
            ShowToast('error', "Something Went Wrong!")
        })
    }
    useEffect(() => {
        if (props.item) {
            setId(props.item.id)
            setSubjectName(props.item.name)
            setGradeId(props.item.grade_id)
        }
    }, [props.item])
    return (
        <Modal
            {...props}
            size="m"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Edit Subject
      </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="add-subject-form">
                    <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="name" placeholder="Enter Subject Name" onChange={(event) => { setSubjectName(event.target.value) }} value={subjectName}/>
                    </Form.Group>
                    <Form.Group controlId="grades_select">
                        <Form.Label>Grade</Form.Label>
                        <Form.Control as="select" onChange={(event) => { setGradeId(event.target.value) }} placeholder="Grade" value={gradeId}>
                            <option value={0}>Select Grade</option>
                            {grades.map(item => (
                                <option key={item.id} value={item.id}>{item.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <BtnDark title="Submit" loading={loading} onClick={(event) => {
                        event.preventDefault()
                        if (gradeId !== null && gradeId !== 0 && subjectName !== null) {
                            editSubjectData()
                        } else {
                            ShowToast('error', "Please enter/select all the fields")
                        }
                    }} />
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default EditSubject
