import React, { useState, useEffect } from 'react'
import { Modal, Button, Form, Spinner } from 'react-bootstrap'
import { AdminContext } from '../../../utils/AdminProvider'
import makeRequest from '../../../utils/makeRequest'
import store from '../../../utils/store'
import ShowToast from '../../../utils/ShowToast'

function EditHomework(props) {
    const [name, setName] = useState("")
    const [gradeId, setgradeId] = useState(0)
    const [subjectId, setSubjectId] = useState(0)
    const [week, setWeek] = useState(0)
    const [active, setActive] = useState(false)
    const [loading, setLoading] = useState(false)
    const { grades, subjects, generateErrors, getHomeworkData, setHomework } = React.useContext(AdminContext)
    const validate = () => {
        if (!name || name.trim() === "") {
            ShowToast('error', "Please enter name")
            return
        }
        if (gradeId === 0) {
            ShowToast('error', "Please select grade")
            return
        }
        if (subjectId === 0) {
            ShowToast('error', "Please select subject")
            return
        }
        if (week === 0) {
            ShowToast('error', "Please enter week number")
        }
        let data = new FormData()
        data.append('name', name)
        data.append('grade_id', gradeId)
        data.append('subject_id', subjectId)
        data.append('week', week)
        if (active) {
            data.append('active', 1)
        } else {
            data.append('active', 0)
        }
        data.append('id', props.item.id)
        updateHomework(data)
    }
    const updateHomework = (data) => {
        setLoading(true)
        makeRequest(store.getState().user.token).post('/editHomework', data).then(res => {
            setLoading(false)
            if (res.data.status_code == 200) {
                ShowToast('success', res.data.message)
                setHomework(res.data.data)
                props.onHide()
            } else {
                ShowToast('error', res.data.message)
                generateErrors(res.data.errors)
            }
        }).catch(err => {
            setLoading(false)
            ShowToast('error', 'Something went wrong')
        })
    }
    useEffect(() => {
        if (props.item) {
            let item = props.item
            setName(item.name)
            setgradeId(item.grade_id)
            setWeek(item.week)
            setActive(item.active == 1 ? true : false)
            setSubjectId(item.subject_id)
        }
    }, [props.item])
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Edit Homework
          </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="add-form">
                    <Form.Group >
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="name" placeholder="Enter Name" onChange={(event) => { setName(event.target.value) }} value={name} />
                    </Form.Group>
                    <Form.Group >
                        <Form.Label>Grade</Form.Label>
                        <Form.Control as="select" onChange={(event) => { setgradeId(event.target.value) }} placeholder="Grade" value={gradeId} >
                            <option value={0}>Select Grade</option>
                            {grades.map(item => (
                                <option key={item.id} value={item.id}>{item.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group >
                        <Form.Label>Subject</Form.Label>
                        <Form.Control as="select" onChange={(event) => { setSubjectId(event.target.value) }} placeholder="Subject" value={subjectId}>
                            <option value={0}>Select Subject</option>
                            {subjects.filter((value) => {
                                if (value.grade_id == gradeId) {
                                    return true
                                } else {
                                    return false
                                }
                            }).map(item => (
                                <option key={item.id} value={item.id}>{item.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group >
                        <Form.Label>Week</Form.Label>
                        <Form.Control type="number" placeholder="Enter Week Number" onChange={(event) => { setWeek(event.target.value) }} value={week} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Check type="checkbox" label="Active" onChange={event => { setActive(event.target.checked) }} checked={active} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide} variant="dark">Close</Button>
                {loading ? <Spinner animation="border" variant="dark" /> : <Button onClick={() => validate()}>Save</Button>}
            </Modal.Footer>
        </Modal>
    )
}

export default EditHomework
