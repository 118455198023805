import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import { useSelector, useDispatch } from 'react-redux'
import { login } from '../actions/loginActions'
import Loader from '../components/Loader'
import { Redirect, useHistory } from 'react-router-dom'
import ShowToast from '../utils/ShowToast'
import { Row, Col } from 'react-bootstrap'
import ForgotPassword from '../components/ForgotPassword'
import ForgotUsername from '../components/ForgotUsername'
function LoginScreen() {
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [forgotUsername, setForgotUsername] = useState(false);
    const [forgotPassword, setForgotPassword] = useState(false);
    const user = useSelector(state => state.user);
    const history = useHistory()
    const dispatch = useDispatch();
    useEffect(() => {
        if (user.errorMessage != "") {
            ShowToast("error", user.errorMessage)
        }
    }, [user.errorMessage])
    return (
        <div>
            <ForgotPassword show={forgotPassword} hide={()=>setForgotPassword(false)}/>
            <ForgotUsername show={forgotUsername} hide={()=>setForgotUsername(false)}/>
            {user.isLoading && <Loader />}
            <div className="d-flex align-items-center min-vh-100 flex-column justify-content-center">
                <Card
                    bg="white"
                    // text='white'
                    style={{ width: '42rem', height: '22rem' }}
                    className="mb-2 text-center shadow-lg border-0 p-0">
                    <Card.Body className="p-0 pr-2">
                        <Row style={{ height: '22rem' }}>
                            <Col style={{ backgroundColor: "#000" }} className="d-flex justify-content-center align-content-center">
                                <img src={require('../resources/logo/loginLogo.jpg')}
                                    style={{ objectFit: "contain", width: "100%" }}
                                    alt="point-cook-tutoring" />
                            </Col>
                            <Col className="justify-content-center d-flex flex-column h-100">
                                <Card.Title>{window.location.host.split(".")[0].toUpperCase()}</Card.Title>
                                <Form>
                                    <Form.Group controlId="formBasicuUername">
                                        <Form.Control className="loginInput" type="username" placeholder="Enter username" onChange={(event) => { setUsername(event.target.value) }} required />
                                        <a className="float-right" onClick={()=>setForgotUsername(true)} style={{cursor:"pointer"}}>Forgot username?</a>

                                    </Form.Group>
                                    <Form.Group controlId="formBasicPassword">
                                        <Form.Control className="loginInput" type="password" placeholder="Password" onChange={(event) => { setPassword(event.target.value) }} required />
                                        <a className="float-right" onClick={()=>setForgotPassword(true)} style={{cursor:"pointer"}}>Forgot password?</a>
                                    </Form.Group>
                                    {/* <Form.Group controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Check me out" />
                            </Form.Group> */}

                                </Form>
                                <Button variant="outline-dark" className="mt-3" type="submit" onClick={(event) => {
                                    event.preventDefault()
                                    dispatch(login(username, password, history))
                                }}>Submit</Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        </div>
    )
}

export default LoginScreen
