/**
 *  Centralize and wraps functionality of local web storage
 */

/**
 *
 * @param type
 * @returns {boolean}
 */
function storageAvailable(type) {
    try {
        var storage = window[type],
            x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    }
    catch (e) {
        return false;
    }
}

class WebStorageTool {
    constructor() {
        this.canSave = storageAvailable('localStorage');
    }
    setItem = (item, value) => {
        if (this.canSave) {
            localStorage.setItem(item, value);
            return true;
        }
        else {
            throw new Error('Cannot save to WebStorage');
        }
    };
    setItems = (itemValues) => {
        if (this.canSave) {
            for (var item in itemValues) {
                localStorage[item] = itemValues[item];
            }
            return true;
        }
        else {
            throw new Error('Cannot save to WebStorage');
        }
    };
    getItem = (item) => {
        if (this.canSave) { return localStorage[item] || undefined; }
        else { throw new Error('Cannot save to WebStorage'); }
    };
    removeItem = (item) => {        
        if (this.canSave) { localStorage.removeItem(item);
         }
        else { throw new Error('Cannot save to WebStorage'); }
    };
}

let WebStorageInstance = new WebStorageTool();
export default WebStorageInstance

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');

        if (serializedState === null) {
            return undefined;
        }

        return JSON.parse(serializedState);
    } catch (error) {
        return undefined;
    }
};

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch (error) {
        // Ignore write errors.
    }
};