import React, { useEffect, useState } from 'react'
import { Row, Card, ListGroup, Container } from 'react-bootstrap'
import NavBar from '../components/NavBar'
import { useDispatch, useSelector } from 'react-redux'
import { getMaster } from '../actions/homeActions'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import Charts from '../components/Charts'
function SelectSchoolHome(props) {
    const [tests, setTests] = useState([])
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector(state => state.user)
    const home = useSelector(state => state.home)
    const [show, setShow] = useState(false)
    const data = home.data
    useEffect(() => {
        if (props.location.state) {
            toast.success(props.location.state.message, {
                autoClose: false,
                position: "top-right",
                progress: undefined,
                draggable: true,
                closeOnClick: true
            })
            history.replace('/home')
        }

    }, [props.location.state])
    useEffect(() => {
        if (user.token != "") {
            dispatch(getMaster())
        }
    }, [user.token]);
    useEffect(() => {
        let tests = [];
        data.forEach(item => {
            tests.push(
                <Card border="light" style={{ width: '18rem' }} className="m-2 shadow-sm p-1 mb-5 bg-white rounded" key={item.id}>
                    <Card.Body>
                        <Card.Title>{item.name}</Card.Title>
                        <ListGroup >
                            {item.active_tests.map(element => (
                                element.user_submission == null ?
                                    <ListGroup.Item
                                        action
                                        key={element.id}
                                        onClick={() => {
                                            history.push('/test', { fromHome: true, test_id: element.id, subject_name: item.name })
                                        }}>{element.name}</ListGroup.Item> :
                                    <ListGroup.Item
                                        variant="success"
                                        action
                                        key={element.id}
                                        onClick={() => {
                                            history.push('/test', { fromHome: true, test_id: element.id, submission: element.user_submission, subject_name: item.name })
                                        }}>{element.name}</ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Card.Body>
                </Card >)
        });
        setTests(tests)
    }, [data])
    useEffect(() => {
        window.addEventListener('scroll', toggleShow)
        return () => {
            window.removeEventListener('scroll', toggleShow)
        }
    }, [])
    const toggleShow = () => {
        if (window.scrollY > 30) {
            setShow(true)
        } else {
            setShow(false)
        }
    }
    return (
        <div>
            <NavBar />
            <Container className="p-5">
                <Charts />
                <Card className="mt-5 m-auto shadow p-3 mb-5 bg-white rounded" >
                    <Card.Title className="mb-2">Tests</Card.Title>
                    <Card.Body>
                        <Row style={{ display: 'flex', justifyContent: "space-around" }}>
                            {tests}
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
            <div style={{
                backgroundColor: "#000",
                position: "fixed",
                bottom: 30,
                right: 30,
                height: 54,
                width: 54,
                borderRadius: 32,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                transition: "all .2s ease-in-out",
                transform: show ? "scale(1)" : "scale(0)",
                cursor:"pointer"
            }}
                className="shadow"
                onClick={()=>{window.scrollTo(0,0)}}>
                <i className="fa fa-chevron-up" style={{ color: "#fff" }} />
            </div>
        </div>
    )
}

export default SelectSchoolHome
