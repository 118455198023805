import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row, Form } from 'react-bootstrap';
import makeRequest from '../utils/makeRequest';
import ShowToast from '../utils/ShowToast';

export default function ChangePassword(props) {
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [loading,setLoading] = useState(false)
    const reset = async () => {
        if(password !== confirmPassword){
            ShowToast("error","Confirm Password does not match")
            return
        }
        setLoading(true)
        let token = new URLSearchParams(props.location.search).get("data");
        let data = new FormData()
        data.append("token",token)
        data.append("password", password)
        data.append("password_confirmation", confirmPassword)
        let response = await makeRequest().post("/changePassword", data)
        if (response.data.status_code == 200) {
            ShowToast("success", response.data.data)
            setTimeout(() => {
                window.location.href = ("https://primary.pointcooktutoring.com/login")
            }, 3000);
        } else {
            setLoading(false)
            ShowToast("error", response.data.data)
        }
        
    }
    return (
        <div className="d-flex align-items-center min-vh-100 flex-column justify-content-center">
            <Card
                bg="white"
                // text='white'
                style={{ width: '42rem', height: '22rem' }}
                className="mb-2 text-center shadow-lg border-0 p-0">
                <Card.Body className="p-0 pr-2">
                    <Row style={{ height: '22rem' }}>
                        <Col style={{ backgroundColor: "#000" }} className="d-flex justify-content-center align-content-center">
                            <img src={require('../resources/logo/loginLogo.jpg')}
                                style={{ objectFit: "contain", width: "100%" }}
                                alt="point-cook-tutoring" />
                        </Col>
                        <Col className="justify-content-center d-flex flex-column h-100">
                            <Card.Title>Change Password</Card.Title>
                            <Form.Group controlId="formBasicuPassword">
                                <Form.Control type="password" placeholder="New Password" onChange={(event) => { setPassword(event.target.value) }} value={password} required />
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Control type="password" placeholder="Confirm Password" onChange={(event) => { setConfirmPassword(event.target.value) }} value={confirmPassword} required />
                            </Form.Group>
                            <Button variant="outline-dark" disabled={loading} type="submit" onClick={(event) => {
                                event.preventDefault()
                                reset()
                                
                            }}>Submit</Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    )
}
